import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  student: {},
  isOpen: false,
};

const schoolProgramSlice = createSlice({
  name: "schoolProgram",
  initialState,
  reducers: {
    updateStudent: (state, action) => {
      state.student = action.payload;
    },
    toggleSchoolProgramModal: (state) => {
      state.isOpen = !state.isOpen;
    },
  },
});

export const { toggleSchoolProgramModal, updateStudent } =
  schoolProgramSlice.actions;

export default schoolProgramSlice.reducer;
