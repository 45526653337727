import React from "react";

const SideNav2 = () => {
  return (
    <>

      <nav className="sidenav">
        <ul>
          <li>
            <a href="#our-top">
              Top
            </a>
          </li>
          <li>
            <a href="#benifits">Programs <br /> Benifits</a>
          </li>
          <li>
            <a href="#Highlights">Program <br /> Highlights</a>
          </li>
          <li>
            <a href="#Syllabus">Program <br /> Syllabus</a>
          </li>
          <li>
            <a href="#Olympiads">Olympiads</a>
          </li>
          
        </ul>
      </nav>
    </>
  );
};

export default SideNav2;
