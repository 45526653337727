import React, { useState } from "react";
import styles from "./StudentPage.module.css";
import RegisterToEpic from "./subpages/NewProgram/RegisterToEpic";
import StudentProfile from "./subpages/profile/StudentProfile";
import RegisteredEpicPrograms from "./subpages/RegisteredPrograms/RegisteredEpicPrograms";
import DownloadCertificate from "./subpages/certificate/DownloadCertificate";

const StudentPage = () => {
  const [selectedTab, setSelectedTab] = useState("profile");

  return (
    <div className={styles.studentPage}>
      <div className={styles.left}>
        <span
          className={`${styles.leftOption} ${
            selectedTab === "profile" ? styles.activeOption : ""
          }`}
          onClick={() => setSelectedTab("profile")}
        >
          Student Profile
        </span>
        <span
          className={`${styles.leftOption} ${
            selectedTab === "register" ? styles.activeOption : ""
          }`}
          onClick={() => {
            setSelectedTab("register");
          }}
        >
          Register To EPIC Program
        </span>
        <span
          className={`${styles.leftOption} ${
            selectedTab === "registered" ? styles.activeOption : ""
          }`}
          onClick={() => {
            setSelectedTab("registered");
          }}
        >
          Registered EPIC Programs
        </span>
        <span
          className={`${styles.leftOption} ${
            selectedTab === "download" ? styles.activeOption : ""
          }`}
          onClick={() => {
            setSelectedTab("download");
          }}
        >
          Download Certificate
        </span>
      </div>
      <div className={styles.right}>
        {selectedTab === "profile" && <StudentProfile />}
        {selectedTab === "register" && <RegisterToEpic />}
        {selectedTab === "registered" && <RegisteredEpicPrograms />}
        {selectedTab === "download" && <DownloadCertificate />}
      </div>
    </div>
  );
};

export default StudentPage;
