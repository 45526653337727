import React, { useEffect, useState } from "react";
import styles from "./SchoolProfiles.module.css";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  toggleProgramModal,
  updateProgram,
} from "../../../../../store/user/programSlice";
import Dropdown from "../dropdown/Dropdown";
import {
  setSchoolProfileOpened,
  updateSchool,
} from "../../../../../store/user/adminSlice";
import SchoolProfile from "../../../school/subpages/profile/SchoolProfile";

const SchoolProfiles = () => {
  const { currentUser } = useSelector((state) => state.user);
  // const { programUpdated } = useSelector((state) => state.program);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [error, setError] = useState("");

  const [allSchools, setAllSchools] = useState([]);
  const [filteredSchools, setFilteredSchools] = useState(allSchools);
  const [allStates, setAllStates] = useState([]);
  const [allDistricts, setAllDistricts] = useState([]);
  const [allCities, setAllCities] = useState([]);

  const [selectedState, setSelectedState] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const { schoolProfileOpened } = useSelector((state) => state.admin);

  const fetchStudentData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/getAllSchools`
      );
      const schools = response.data;
      const states = [...new Set(schools.map((school) => school.add_state))];

      console.log("All schools", schools);

      setAllSchools(schools);
      setFilteredSchools(schools);
      setAllStates(states);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching student data:", err);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStudentData();
  }, [currentUser]);

  useEffect(() => {
    setSelectedDistrict("");
    setSelectedCity("");
    if (selectedState === "") {
      setFilteredSchools(allSchools);
      setAllDistricts([]);
      setAllCities([]);
    } else {
      const currStateStudents = allSchools.filter(
        (student) => student.add_state === selectedState
      );
      const allDistricts = [
        ...new Set(currStateStudents.map((student) => student.add_dist)),
      ];
      setAllDistricts(allDistricts);
      setFilteredSchools(currStateStudents);
    }
  }, [selectedState]);

  useEffect(() => {
    if (selectedState === "") return;
    setSelectedCity("");
    if (selectedDistrict === "") {
      const currStateStudents = allSchools.filter(
        (student) => student.add_state === selectedState
      );
      setFilteredSchools(currStateStudents);
      setAllCities([]);
      console.log(currStateStudents);
      // alert("Empty one");
    } else {
      const currDistrictStudents = allSchools.filter(
        (student) => student.add_dist === selectedDistrict
      );
      const allCities = [
        ...new Set(currDistrictStudents.map((student) => student.add_city)),
      ];
      setAllCities(allCities);
      setFilteredSchools(currDistrictStudents);
    }
  }, [selectedDistrict]);

  useEffect(() => {
    if (selectedDistrict === "") return;
    if (selectedCity === "") {
      const currDistrictStudents = allSchools.filter(
        (student) => student.add_dist === selectedDistrict
      );
      setFilteredSchools(currDistrictStudents);
    } else {
      const currCityStudents = allSchools.filter(
        (student) => student.add_city === selectedCity
      );
      setFilteredSchools(currCityStudents);
    }
  }, [selectedCity]);

  const handleOpenForm = (school) => {
    console.log("Current school: ", school);
    dispatch(updateSchool(school));
    dispatch(setSchoolProfileOpened(true));
    window.scrollTo(0, 0);
  };

  const handelDeleteSchool = async (school) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/deleteSchool/${school._id}/${school.user_id._id}`
      );

      console.log(response);
      if (response.status === 200) {
        fetchStudentData();
        alert("School deleted successfully");
      }
    } catch (error) {
      console.error("Failed to delete school: ", error);
      alert("Failed to delete school");
    }
  };

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <p className={styles.loading}>Loading...</p>;
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      {!schoolProfileOpened ? (
        <div className={styles.studentPage}>
          <h2 className={styles.h2}>All Schools Data</h2>
          <div className={styles.selectContainer}>
            <Dropdown
              category={"State"}
              options={allStates}
              selectedOption={selectedState}
              setSelectedOption={setSelectedState}
            />
            <Dropdown
              category={"District"}
              options={allDistricts}
              selectedOption={selectedDistrict}
              setSelectedOption={setSelectedDistrict}
            />
            <Dropdown
              category={"City"}
              options={allCities}
              selectedOption={selectedCity}
              setSelectedOption={setSelectedCity}
            />
          </div>
          <div className={styles.tableContainer}>
            {filteredSchools.length > 0 ? (
              <table className={styles.programsTable}>
                <thead>
                  <tr>
                    <th className={styles.th}>School Name</th>
                    <th className={styles.th}>State</th>
                    <th className={styles.th}>District</th>
                    <th className={styles.th}>City</th>
                    <th className={styles.th}>Update</th>
                    <th className={styles.th}>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredSchools.map((school, index) => {
                    // const key = Object.keys(program);
                    // const studentName = key[0];
                    // const studentProgram = program[studentName];
                    return (
                      <tr key={index}>
                        <td className={styles.td}>{school.school_name}</td>
                        <td className={styles.td}>{school.add_state}</td>
                        <td className={styles.td}>{school.add_dist}</td>
                        <td className={styles.td}>{school.add_city}</td>
                        <td className={styles.td}>
                          <span
                            className={`${styles.details} ${styles.updateButton}`}
                            onClick={() => handleOpenForm(school)}
                          >
                            Update
                          </span>
                        </td>
                        <td className={styles.td}>
                          <span
                            className={`${styles.details} ${styles.deleteButton}`}
                            onClick={() => handelDeleteSchool(school)}
                          >
                            Delete
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <p className={styles.p}>
                Sorry!! No Record Found for this location.
              </p>
            )}
          </div>
        </div>
      ) : (
        <>
          <button
            className={styles.goBackButton}
            onClick={() => dispatch(setSchoolProfileOpened(false))}
          >
            &laquo; Go Back
          </button>
          <SchoolProfile />
        </>
      )}
    </>
  );
};
export default SchoolProfiles;
