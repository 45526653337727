import React, { useRef } from "react";
import { useEffect } from 'react'

import "./Ourprogram.css";
import "./Course.css"
import { NavLink } from "react-router-dom";
import WebDevelopmentSlider from "../../components/cardsliders/WebDevelopmentSlider";
import AccordionComp from "../../components/AccordionComp/AccordionComp";
// import syllabus1 from "../../assets/1-5th.png"
// import syllabus2 from "../../assets/syllabus2.png"
// import syllabus3 from "../../assets/syllabus3.png"
// import syllabus4 from "../../assets/syllabus4.png"
// import syllabus5 from "../../assets/syllabus5.png"
// import syllabus6 from "../../assets/syllabus6.png"
// import syllabus7 from "../../assets/syllabus7.png"
// import syllabus8 from "../../assets/syllabus8.png"
// import syllabus9 from "../../assets/syllabus9.png"
// import syllabus10 from "../../assets/syllabus10.png"
// import syllabus11 from "../../assets/syllabus11.png"
// import syllabus12 from "../../assets/syllabus12.png"
import Syllabus from "../../components/Syllabus/Syllabus";
import background1 from "../../assets/primary.jpg";
import background2 from "../../assets/highsecondary.jpg";
import background3 from "../../assets/8-9th.jpg";
import background4 from "../../assets/11-12th.jpg";
import CourseCard2 from "../Pages/CourseCard2";
import Program from "../Programhightlight/Program";
import Olympiad from "../../components/olympaid/Olympiad";
import SideNav2 from "../../components/Navbar/SideNav2";
import { useLocation } from 'react-router-dom'


// import { NavLink } from "react-router-dom";



const Ourprogram = () => {
  const location = useLocation();

  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);
  const detailedSyllabusRef = useRef(null);

  const scrollToDetailedSyllabus = () => {
    detailedSyllabusRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  return (
    <>
      <SideNav2 />
      <div className='relativeContainer'>
        <div id="our-top"></div>
      </div>
      <section className="webdevelop-top" >
        <div className="webdevelopment-top container">
          <div className="webdevelopment-top-left">
            <h1>1. Nurturing Future Innovation</h1>
            <h1>2. Moving Technology Accessible to everyone</h1>
            <h1>3. Empowering Future with Technology</h1>
            <NavLink className="cta" to={"/contact"}>
              Get a Quote
            </NavLink>
          </div>
          <div className="webdevelopment-top-right">
            <WebDevelopmentSlider />
          </div>
        </div>
      </section>
      {/* Start Front Intro Section  */}
      <br /><br />
      <h1 className="section-title">
        EPIC <span>Programs</span>
      </h1><br /><br />
      <section id="course-top-mid">
        <div className=" top-mid">
          <div className="course-topCard column br3-orange">
            <h1>Duration</h1>
            <p>06 Months </p>
          </div>
          <div className="course-topCard column br3-orange">
            <h1>Week End Classes </h1>
            <p>(Sat & Sunday)</p>
          </div>
          <div className="course-topCard column br3-orange">
            <h1>Live</h1>
            <p>02 Hrs</p>
          </div>
          <div className="course-topCard column ">
            {/* <h1>Monthly </h1>
            <p>assessment Test</p> */}
            <h1>Class&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;Fees &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h1>
            <p>1st to 5th - ₹2499 (₹500/month)</p>
            <p>6th to 8th - ₹3699 (₹650/month)</p>
            <p>9th to 10th - ₹3999 (₹750/month)</p>
            <p>11th to 12th - ₹4999 (₹900/month)</p>

          </div>
        </div>
      </section>
      <section className="section center about2">
        <div className="it">
          <div className="card-about2 article">
            <div className="card2-info">
              <p>
                The EPIC (Education Program Council of India) programs differs from traditional computer learning by emphasizing real-world applications, hands-on projects, and community engagement, which fosters a more dynamic and practical understanding of technology compared to the conventional classroom learning’s.
              </p>
              <br />
              <p>We aim to empower young minds with the skills and knowledge necessary to thrive in the digital age. We are dedicated to providing comprehensive computer education to primary and middle school students across the country, ensuring that every child has the opportunity to become proficient in technology when they graduated to higher studies.</p>
              <br />
              <p>Our teaching methods focus on interactive and practical learning, ensuring that students not only understand the concepts but also apply them in real-world scenarios.
              </p>
            </div>
            <div className="card2-img">
              <img src="assets/technical.jpg" alt="fundamental" />{" "}
            </div>
          </div>
        </div>
      </section>


      <div className="content">
        <h1 className="headline section-title-left">EPIC Programs  <span>Offer</span> </h1>
        <h3 className='about-left-h3'>Our programs are designed to cater to the learning needs of students from Junior
          &primary <br /> to middle school. EPIC offers a range of activities and courses, including;
        </h3>
        <br />
        <br /><br />
      </div>
      <div className="Home-container-home home-our">
        <section className=" section center">
          <div className="it-staffing-services">
            <div className="card-about-main2  article">
              <div className="card2-info">
                <h1>Basic Computer Literacy:</h1>
                {/* <h4 className="heading4">FULL-TIME TECH TALENT</h4> */}
                <p>
                  Introducing students to the fundamentals of computer operation, internet safety, and essential software applications like MS office (MS World, Excel & Power point).
                </p>
                <h1>Coding and Programming</h1>
                <p>
                  Engaging students in fun and interactive coding lessons using platforms like, Python, C++ , Java and other beginner-friendly programming languages with  Data Science.
                </p>
              </div>
              <div className="card2-img">
                <img src="assets/coading.jpg" alt="coading" />{" "}

              </div>
            </div>
          </div>
        </section>
        <section className=" section center">
          <div className="it-staffing-services">
            <div className="card-about-main2 article">
              <div className="card2-info">
                <h1>Artificial Intelligence (AI)</h1>
                {/* <h4 className="heading4"></h4> */}
                <p>
                  The integration of AI into everyday applications continues to grow, enhancing efficiency and creating new opportunities across industries. It is revolutionizing how we interact with technology and solve complex problems.

                </p>
                <h1>Robotics and STEM</h1>
                <p>
                  Encouraging creativity and problem-solving through hands-on projects involving robotics, electronics, and other STEM (Science, Technology, Engineering, and Mathematics) activities.
                </p>
              </div>
              <div className="card2-img">
                <img src="assets/arti-int.jpg" alt="mentorship" />{" "}
              </div>
            </div>
          </div>
        </section>
        <section className=" section center">
          <div className="it-staffing-services">
            <div className="card-about-main2 article">
              <div className="card2-info">
                <h1>
                  Web Development</h1>
                {/* <h4 className="heading4">
                </h4> */}
                <p>
                  Introduce the students about Web Technologies and engage them with Web Designing & Development technologies including HTML, CSS, Java Script, client server programming.
                </p>
                <h1>Cloud Computing</h1>
                <p>
                  Cloud Computing is the rapidly increasing market today in IT industry. Students will get basic introduction about cloud computing and learn technology & tools are used today for Cloud Computing.
                </p>
              </div>
              <div className="card2-img">
                <img src="assets/web.jpg" alt="web" />{" "}
              </div>
            </div>
          </div>
        </section>

        <section className=" section center">
          <div className="it-staffing-services">
            <div className="card-about-main2 article">
              <div className="card2-info">
                <h1>Workshops and Camps</h1>
                {/* <h4 className="heading4">
                </h4> */}
                <p>
                  Organizing regular workshops and camps to provide intensive learning experiences and foster collaboration among young tech enthusiasts.
                </p>
              </div>
              <div className="card2-img">
                <img src="assets/workshops.jpg" alt="work" />{" "}
              </div>
            </div>
          </div>
        </section>
      </div>
      <br /><br />
      {/* End Fron */}
      <br />
      <div className='relativeContainer'>
        <div id="benifits"></div>
      </div>
      <h1 className="section-title">
        Benefits of joining <span>EPIC’s Programs</span>
      </h1><br /><br />
      <CourseCard2 />
      <br />
      {/* Curriculum Start */}
      <div className='relativeContainer'>
        <div id="Highlights"></div>
      </div>
      <Program />
      <br /><br />
      <br /><br />
      <div className='relativeContainer'>
        <div id="Syllabus"></div>
      </div>
      <h1 className="section-title">
        Program’s  <span>Syllabus</span>
      </h1>
      <h3 className="heading6">
        <span>⦁	EPIC’s Syllabus is completely innovative, Practical  & Futuristic which is not or may not &nbsp;&nbsp;&nbsp;resemble to traditional academic syllabus</span>
      </h3>
      <h3 className="heading6">
        <span>⦁	Tailored program for fast learning’s.</span>
      </h3>
      <h3 className="heading6">
        <span>⦁	Teaching in Hindi & English both to enhance their communication in English as well.</span>
      </h3>
      <div id='relativeContainer'>
        <div id="cop"></div>
      </div>
      <br /><br />
      <h1 className="headline4 section-title">COP <span> (Computer Outreach Program) </span></h1>
      <p className="paragraph">By targeting Primary, Middle, Secondary and Higher Secondary school students, the Computer Outreach Program in India aims to instill a strong foundation in technology from an early age, setting the stage for future educational and career opportunities in the digital world. </p>
      <p className="paragraph">This program focuses on equipping young learners with foundational computer skills, coding knowledge, and digital literacy, ensuring they are prepared for the future digital economy.</p>
      <p className="paragraph">EPICs Computer Outreach Program, where we bring the world of technology directly to primary and middle school students across India. Our goal is simple: to equip every child with essential digital skills and inspire a passion for learning in a digital world. </p>
      <br /><br />

      <Syllabus
        backgroundImage={background1} property2={""}
        props={{
          main: "Primary (Class 1st- 5th)(Junior Pioneers- Learn with Fun)",
          li1: "⦁ Introduction to Computers.",
          li2: "⦁ Input & Output devices.",
          li3: "⦁ Operating Systems",
          li4: "⦁ Uses of computers (Drawing, Gaming, Chatting).",
          li5: (
            <>
              ⦁ Introduction to drawing S/w and having fun with drawing using MS <br />
              <span style={{ marginLeft: '10px' }}></span>Paint, Tux Paint.
            </>
          ),
          li6: "⦁ Typing skills using MS Word and Basic word processing.",
          li7: "⦁ Have fun by making presentation using power point.",
          li8: "⦁ Basic internet introduction and demo. Browsing and Using internet.",
          li9: "⦁ Basic programming concepts and block based programming.",
          buttons: [
            { text: "Detailed Syllabus", href: "#detailed" },
          ]
        }}
      />
      <br /><br />
      <Syllabus backgroundImage={background2} property={"space-between"}
        props={{
          main: "Middle(Class 6th -8th)(Early Learners-Focused Learning)",
          li1: "⦁ Introduction to Computer Basics & Advance. Different computer Parts.",
          li2: "⦁ Introduction to Software & Hardware, Difference Between S/w & H/w",
          li3: "⦁ Operating Systems. Introduction to Different OS.",
          li4: "⦁ Detailed on Windows Operating System.",
          li5: "⦁ Word Processing & Spread Sheet formatting (MS Word & Excel).",
          li6: "⦁ Making Different types of presentations (MS Power point).",
          li7: "⦁ Introduction to internet & its uses. ",
          li8: "⦁ Social Media uses, Social Media Ethics.",
          li9: "⦁ E-mail (How to create, its uses).  ",
          li10: "⦁ E-Commerce, Cyber Security & Ethics.",
          li11: "⦁ Introduction to computer programming languages.",
          li12: "⦁ Basic programming in Python.",
          li13: "⦁ Introduction to AI, Different types of AI and uses.",
          li14: "⦁ Basics of Generative AI & AI Tools.",
          buttons: [
            { text: "Detailed Syllabus", href: "#detailed" },
          ]
        }}

      />
      <br /><br />

      <Syllabus backgroundImage={background3}
        props={{
          main: "	Secondary(9th -10th )(“Curious Minds-Foundations for Success)",
          li1: "⦁ Introduction to Information Technology and its scope.",
          li2: "⦁ MS Office (Word, Excel & Power point) with practical & projects.",
          li3: "⦁  Networking & Internet uses.",
          li4: "⦁  Cyber Security & Cyber Ethics with practical.",
          li5: (
            <>
              ⦁ Introduction to programming languages, Generations & different <br />
              <span style={{ marginLeft: '10px' }}></span>types of computer Languages..
            </>
          ),
          li6: "⦁  Basic Python Programming with practical.",
          li7: "⦁  Introduction to Web Technology & Client/Servicer Architecture.",
          li8: "⦁  HTML Programming & CSS.",
          li9: "⦁  Introduction to DBMS (Data Base Management System)",
          li10: "⦁ Introduction to AI & Its uses.",
          li11: "⦁ AI Tools used today.",
          li12: "⦁ Basics of cloud computing.",
          buttons: [
            { text: "Detailed Syllabus", href: "#detailed" },
          ]
        }}

      />
      <br /><br />

      <Syllabus backgroundImage={background4} property={"space-between"}
        props={{
          main: "Higher Secondary(11th-12th)(Future Innovators-Creative Coding)  ",
          li1: "⦁ Introduction to Information Technology and its scope.",
          li2: (
            <>
              ⦁ Introduction to Different types of Computer Technologies available<br />
              <span style={{ marginLeft: '10px' }}></span>today.
            </>
          ),
          li3: "⦁ MS Office (Word, Excel & Power point) with practical & projects.",
          li4: (
            <>
              ⦁ Introduction to programming languages & different types of computer <br />
              <span style={{ marginLeft: '10px' }}></span>Languages.
            </>
          ),
          li5: (
            <>
              ⦁ Generation of Programming languages & Computer programming <br />
              <span style={{ marginLeft: '10px' }}></span>concepts.
            </>
          ),
          li6: (
            <>
              ⦁ Programming using ‘Python’ (implementing all programming <br />
              <span style={{ marginLeft: '10px' }}></span>concepts).
            </>
          ),
          li7: "⦁ OOPS concepts & C++ Programming.",
          li8: "⦁ DBMS & RDBMS",
          li9: "⦁ Oracle & PL/SQL",
          li10: "⦁ Introduction to Web Technology & Client/Servicer Architecture.",
          li11: "⦁ Web Designing & Wire framing. ",
          li12: "⦁ HTML Programming & CSS ",
          li13: "⦁ AI & Its area of uses. Different types of AI Technology.",
          li14: "⦁ Generative AI & AI Tools.",
          li15: (
            <>
              ⦁ Introduction to Cloud Computing & Different cloud computing<br />
              <span style={{ marginLeft: '10px' }}></span>technology available today.
            </>
          ),
          buttons: [
            { text: "Detailed Syllabus", href: "#detailed" },
          ]

        }} />
      <div id="detailed"></div>
      <br /><br /> <br /><br /> <br /><br /> <br />
      <section><h1 className="section-title" >
        Detailed <span> Syllabus</span>
      </h1>
        <br />
        <div className="course-AccordionComp" >
          <div className="course-AccordionComp-left">
            <AccordionComp
              props={{
                title: "Primary(Class 1st-5th)(Junior Pioneers)"
              }}
              src={5}
            />
            <AccordionComp
              props={{
                title1: "Middle(Class 6th-8th)(Early Learners)",
              }}
              src={8}
            />
            <AccordionComp
              props={{
                title2: "Secondary(Class 9th-10th)(Curious Minds) ",
              }}
              src={10}
            />
            <AccordionComp
              props={{
                title3: `Higher Sec.(Class 11th-12th) 
                (Future Innovators)`,
              }}
              src={12}
            />
          </div>
        </div>
      </section>
      {/* Curriculum end */}
      <div id='relativeContainer'>
        <div id="stop"></div>
      </div>
      <br /> <br /><br />
      <h1 className="headline3 section-title"> STOP <span>(Science & Technology Outreach Program)
      </span></h1>
      <p className="paragraph2">We believe in bridging the gap between classroom learning and real-world applications. Through hands-on activities, engaging workshops, and interactive sessions, we ignite curiosity and inspire the next generation of innovators. Our mission is to make science and technology accessible and exciting for students of all. This program will be starting soon……</p>
      <div id='relativeContainer'>
        <div id="Olympiads"></div>
      </div>
      <br /><br />
      <Olympiad />
      <br />
    </>
  );
};

export default Ourprogram;
