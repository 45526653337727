import React, { useEffect, useState } from "react";
import styles from "./StudentsRegisteredInEpic.module.css";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  toggleProgramModal,
  updateProgram,
} from "../../../../../store/user/programSlice";

const StudentsRegisteredInEpic = () => {
  const { currentUser } = useSelector((state) => state.user);
  const { programUpdated } = useSelector((state) => state.program);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [registeredPrograms, setRegisteredPrograms] = useState([
    {
      program: "COP (Computer Outreach Program)",
      status: "Completed",
      details: "view details",
    },
    { program: "Junior Olympiads", status: "Pending", details: "view details" },
  ]);

  const [allRegisteredPrograms, setAllRegisteredPrograms] = useState([]);
  useEffect(() => {
    const fetchStudentData = async () => {
      try {
        const data2 = [];
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/getAllStudents`
        );
        const students = response.data;

        console.log("All students", students);

        // Use Promise.all to handle multiple async operations
        const promises = students.map(async (student) => {
          const name = student.first_name + " " + student.last_name;
          console.log(name);

          const response2 = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/getRegisteredPrograms/${student._id}`
          );
          const allPrograms = response2.data.programs;

          allPrograms.forEach((program) => {
            const currRow = { [name]: program };
            data2.push(currRow);
          });
        });

        await Promise.all(promises); // Wait for all student program fetches to complete

        console.log("data2", data2);
        setAllRegisteredPrograms(data2);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching student data:", err);
        setLoading(false);
      }
    };

    fetchStudentData();
  }, [currentUser, programUpdated]);

  const handleOpenForm = (program) => {
    console.log("Current program: ", program);
    dispatch(updateProgram(program));
    dispatch(toggleProgramModal());
  };

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <p className={styles.loading}>Loading...</p>;
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className={styles.studentPage}>
      <h2 className={styles.h2}>Registered EPIC Programs</h2>
      <div className={styles.tableContainer}>
        {allRegisteredPrograms.length > 0 ? (
          <table className={styles.programsTable}>
            <thead>
              <tr>
                <th className={styles.th}>Student Name</th>
                <th className={styles.th}>Grade</th>
                <th className={styles.th}>Program</th>
                <th className={styles.th}>Status</th>
                <th className={styles.th}>Details</th>
              </tr>
            </thead>
            <tbody>
              {allRegisteredPrograms.map((program, index) => {
                const key = Object.keys(program);
                const studentName = key[0];
                const studentProgram = program[studentName];
                return (
                  <tr key={index}>
                    <td className={styles.td}>{studentName}</td>
                    <td className={styles.td}>
                      {studentProgram.student_id.std_grade}
                    </td>
                    <td className={styles.td}>
                      {studentProgram.prog_id.prog_name}
                    </td>
                    <td className={styles.td}>{studentProgram.prog_status}</td>
                    <td className={styles.td}>
                      <span
                        className={styles.details}
                        onClick={() => handleOpenForm(studentProgram)}
                      >
                        View details
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <p className={styles.p}>
            Sorry!! Not registered to any EPIC Program.
          </p>
        )}
      </div>
    </div>
  );
};

export default StudentsRegisteredInEpic;
