import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TillClass5 from "./syllabus/TillClass5";
import TillClass8 from "./syllabus/TillClass8";
import TillClass10 from "./syllabus/TillClass10";
import TillClass12 from "./syllabus/TillClass12";

const AccordionComp = ({ props = {}, src }) => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const { btn } = props;

  return (
    <div>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        sx={{ boxShadow: "0 0 0.2rem black", margin: "0.2rem", borderRadius: '5px' }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          className="accordion-summary"
          sx={{ '&:hover': { backgroundColor: '#00d9ff' }, }}
        >
          <Typography
            variant="h4"
            sx={{ width: `${props.titleWidth}`, flexShrink: 0, marginLeft: '2rem' }}
          >
            <ul>
              <li>
                <h4 className="accodianh41">{props.title}</h4>
              </li>
              <li>
                <h4 className="accodianh42">{props.title1}</h4>
              </li>
              <li>
                <h4 className="accodianh43">{props.title2}</h4>

              </li>
              <li>
                <h4 className="accodianh44">{props.title3}</h4>
              </li>
            </ul>
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ borderTop: '1px solid #00d9ff' }}>
          <Typography className="job-description" component="div">
            {/* Display the image if src is provided */}
            {src === 5 && (
              <TillClass5 />
            )}
            {
              src === 8 && (
                <TillClass8 />
              )
            }
            {
              src === 10 && (
                <TillClass10 />
              )
            }
            {
              src === 12 && (
                <TillClass12 />
              )
            }
            {btn && (
              <a href="#apply">
                <button className="cta">Apply</button>
              </a>
            )}
          </Typography>
        </AccordionDetails>

      </Accordion>
    </div>
  );
};

export default AccordionComp;
