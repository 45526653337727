import React, { useEffect, useState } from "react";
import styles from "./StudentProfile.module.css";
import axios from "axios";
import { useSelector } from "react-redux";

const StudentProfile = () => {
  const [studentData, setStudentData] = useState(null);
  const { currentUser } = useSelector((state) => state.user);
  const { student } = useSelector((state) => state.admin);
  const [formData, setFormData] = useState({
    first_name: currentUser && studentData ? studentData.first_name : "",
    last_name: "",
    father_name: "",
    mobile_no: "",
    email: "",
    username: "",
    password: "",
    confirmPassword: "",
    date_of_birth: "",
    std_grade: "",
    schoolName: "",
    schoolType: "Primary",
    schoolAddress: "",
    add_city: "",
    add_dist: "",
    add_state: "",
    add_pin: "",
    gender: "male",
    aadhar_number: "",
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const validatemobile_no = (mobile) => {
    const mobilePattern = /^[0-9]{10}$/;
    return mobilePattern.test(mobile);
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const validatePasswordStrength = (password) => {
    return password.length >= 8; // Example: Require a minimum of 8 characters
  };

  // const sleep = () => {
  //   return new Promise((resolve) => {
  //     setTimeout(() => {
  //       setFormData({
  //         first_name: "",
  //         last_name: "",
  //         father_name: "",
  //         mobile_no: "",
  //         email: "",
  //         username: "",
  //         password: "",
  //         confirmPassword: "",
  //         date_of_birth: "",
  //         std_grade: "",
  //         schoolName: "",
  //         schoolType: "Primary",
  //         schoolAddress: "",
  //         add_city: "",
  //         add_dist: "",
  //         add_state: "",
  //         add_pin: "",
  //         gender: "male",
  //       });
  //     }, 500);
  //   });
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validatemobile_no(formData.mobile_no)) {
      alert("Please enter a valid 10-digit mobile number.");
      return;
    }
    if (!validateEmail(formData.email)) {
      alert("Please enter a valid email address.");
      return;
    }
    if (!validatePasswordStrength(formData.password)) {
      alert("Password must be at least 8 characters long.");
      return;
    }
    if (formData.password !== formData.confirmPassword) {
      alert("Passwords do not match.");
      return;
    }

    setLoading(true);
    setError("");
    setSuccessMessage("");
    window.scrollTo(0, 0);
    try {
      // Make the request to your backend API
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/api/updateStudent/${studentData._id}`,
        formData
      ); // Replace with your backend URL

      console.log("Response:", response);
      console.log("Response status:", response.status);

      if (response.status === 200) {
        setSuccessMessage("Student data updated successfully");
        alert("Student data updated successfully");
      }
    } catch (error) {
      setError(
        error.response ? error.response.data.error : "Registration failed"
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchStudentData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/students/${
            currentUser.user_type !== "student"
              ? student.user_id._id
              : currentUser.user._id
          }`
        );
        const data = response.data[0];
        setStudentData(data); // Set the student data
        setFormData({
          first_name: data.first_name,
          last_name: data.last_name,
          father_name: data.father_name,
          mobile_no: data.mobile_no,
          email: data.email,
          username: data.user_id.username,
          password: "",
          confirmPassword: "",
          date_of_birth: data.date_of_birth.split("T")[0],
          std_grade: data.std_grade,
          schoolName: data.schoolName,
          schoolType: data.schoolType,
          schoolAddress: data.schoolAddress,
          add_city: data.add_city,
          add_dist: data.add_dist,
          add_state: data.add_state,
          add_pin: data.add_pin,
          gender: data.gender,
          aadhar_number: data.aadhar_number ? data.aadhar_number : "",
        });
        setLoading(false); // Data is loaded
        console.log("Student data:", response.data[0]);
      } catch (err) {
        console.error("Error fetching student data:", err);
        setError("Could not fetch student data");
        setLoading(false);
      }
    };

    fetchStudentData();
  }, [currentUser]);

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <p className={styles.loading}>Loading...</p>;
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      <h2 className={`${styles.title} ${styles.h2}`}>Edit your profile</h2>

      <form className={styles.form} onSubmit={handleSubmit}>
        {/* <div className={styles.section}> */}
        {/* <div className={styles.label}> */}
        <div className={styles.grid}>
          <div>
            <label className={styles.label}>
              First Name <span className={styles.required}>*</span>
            </label>
            <input
              type="text"
              name="first_name"
              value={formData.first_name}
              onChange={handleChange}
              required
              className={styles.input}
            />
            {/* </div> */}
          </div>

          <div>
            <label className={styles.label}>
              Last Name <span className={styles.required}>*</span>
            </label>
            <input
              type="text"
              name="last_name"
              value={formData.last_name}
              onChange={handleChange}
              required
              className={styles.input}
            />
          </div>

          <div>
            <label className={styles.label}>
              Father Name <span className={styles.required}>*</span>
            </label>
            <input
              type="text"
              name="father_name"
              value={formData.father_name}
              onChange={handleChange}
              required
              className={styles.input}
            />
          </div>

          <div>
            <label className={styles.label}>
              Mobile Number <span className={styles.required}>*</span>
            </label>
            <input
              type="tel"
              name="mobile_no"
              value={formData.mobile_no}
              onChange={handleChange}
              required
              pattern="[0-9]{10}"
              title="Please enter a valid 10-digit number"
              className={styles.input}
            />
          </div>

          <div>
            <label className={styles.label}>
              Email Address <span className={styles.required}>*</span>
            </label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              className={styles.input}
            />
          </div>

          <div>
            <label className={styles.label}>
              Username <span className={styles.required}>*</span>
            </label>
            <input
              type="text"
              name="username"
              value={formData.username}
              onChange={handleChange}
              required
              className={styles.input}
            />
            {/* Implement DB check for unique username */}
          </div>

          <div>
            <label className={styles.label}>
              Password <span className={styles.required}>*</span>
            </label>
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
              pattern=".{8,}"
              title="Password must be at least 8 characters long"
              className={styles.input}
            />
          </div>

          <div>
            <label className={styles.label}>
              Confirm Password <span className={styles.required}>*</span>
            </label>
            <input
              type="password"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
              required
              className={styles.input}
            />
          </div>

          <div>
            <label className={styles.label}>
              Date of Birth <span className={styles.required}>*</span>
            </label>
            <input
              type="date"
              name="date_of_birth"
              value={formData.date_of_birth}
              onChange={handleChange}
              required
              className={styles.input}
            />
          </div>

          <div>
            <label className={styles.label}>
              Gender <span className={styles.required}>*</span>
            </label>
            <select
              name="gender"
              value={formData.gender}
              onChange={handleChange}
              className={styles.select}
            >
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </select>
            {/* </div> */}

            {/* <div className={styles.section}> */}
          </div>

          <div>
            <label className={styles.label}>
              Grade <span className={styles.required}>*</span>
            </label>
            <select
              name="std_grade"
              value={formData.std_grade}
              onChange={handleChange}
              required
              className={styles.select}
            >
              <option value="">Select</option>
              <option value="1-5">1-5</option>
              <option value="6-8">6-8</option>
              <option value="9-10">9-10</option>
              <option value="11-12">11-12</option>
            </select>
          </div>

          <div>
            <label className={styles.label}>
              School Name <span className={styles.required}>*</span>
            </label>
            <input
              type="text"
              name="schoolName"
              value={formData.schoolName}
              onChange={handleChange}
              required
              className={styles.input}
            />
          </div>

          <div>
            <label className={styles.label}>
              School Type <span className={styles.required}>*</span>
            </label>
            <select
              name="schoolType"
              value={formData.schoolType}
              onChange={handleChange}
              required
              className={styles.select}
            >
              <option value="Primary">Primary</option>
              <option value="Middle">Middle</option>
              <option value="Secondary">Secondary</option>
              <option value="Higher_Secondary">Higher Secondary</option>
              <option value="K_12">K-12</option>
            </select>
          </div>

          <div>
            <label className={styles.label}>
              School Address <span className={styles.required}>*</span>
            </label>
            <input
              type="text"
              name="schoolAddress"
              value={formData.schoolAddress}
              onChange={handleChange}
              required
              className={styles.input}
            />
          </div>

          <div>
            <label className={styles.label}>
              City <span className={styles.required}>*</span>
            </label>
            <input
              type="text"
              name="add_city"
              value={formData.add_city}
              onChange={handleChange}
              required
              className={styles.input}
            />
          </div>
          <div>
            <label className={styles.label}>
              District <span className={styles.required}>*</span>
            </label>
            <input
              type="text"
              name="add_dist"
              value={formData.add_dist}
              onChange={handleChange}
              required
              className={styles.input}
            />
          </div>
          <div>
            <label className={styles.label}>
              State <span className={styles.required}>*</span>
            </label>
            <input
              type="text"
              name="add_state"
              value={formData.add_state}
              onChange={handleChange}
              required
              className={styles.input}
            />
          </div>
          <div>
            <label className={styles.label}>
              Pin Code <span className={styles.required}>*</span>
            </label>
            <input
              type="text"
              name="add_pin"
              value={formData.add_pin}
              onChange={handleChange}
              required
              className={styles.input}
            />
          </div>
          <div>
            <label className={styles.label}>Aadhar number</label>
            <input
              type="text"
              name="aadhar_number"
              value={formData.aadhar_number}
              onChange={handleChange}
              className={styles.input}
            />
          </div>
        </div>

        <div className={styles.submitBtnBox}>
          <button type="submit" className={styles.submitBtn}>
            {loading ? "Updating..." : "Update"}
          </button>
        </div>

        <div className={styles.formGroup}>
          {error && (
            <p className={styles.linkGroup} style={{ color: "red" }}>
              {error}
            </p>
          )}

          {successMessage && (
            <p className={styles.linkGroup} style={{ color: "green" }}>
              {successMessage}
            </p>
          )}
        </div>
      </form>
    </>
  );
};

export default StudentProfile;
