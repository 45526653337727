import React, { useEffect, useState } from "react";
import styles from "./EditProgram.module.css";
import axios from "axios";
import { useSelector } from "react-redux";

const EditProgram = ({ fetchProgramsData }) => {
  const [programData, setProgramData] = useState(null);
  const { program } = useSelector((state) => state.admin);
  //   const { currentUser } = useSelector((state) => state.user);
  const [formData, setFormData] = useState({
    prog_name: "",
    prog_type: "",
    prog_description: "",
    prog_duration: "",
    level: "",
    prog_fee: "",
    start_date: "",
    end_date: "",
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    setError("");
    setSuccessMessage("");
    window.scrollTo(0, 0);

    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/api/updateProgram/${programData._id}`,
        formData
      );

      console.log("Response:", response);
      console.log("Response status:", response.status);
      fetchProgramsData();
      if (response.status === 200) {
        setSuccessMessage("Program data updated successfully");
        alert("Program data updated successfully");
      }
    } catch (error) {
      setError(
        error.response ? error.response.data.error : "Registration failed"
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchProgramData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/programs/${program._id}`
        );
        const data = response.data[0];
        setProgramData(data);
        setFormData({
          prog_name: data.prog_name,
          prog_type: data.prog_type,
          prog_description: data.prog_description,
          prog_duration: data.prog_duration,
          level: data.level,
          prog_fee: data.prog_fee,
          start_date: data.start_date.split("T")[0],
          end_date: data.end_date.split("T")[0],
        });
        setLoading(false);
        console.log("Program data:", response.data[0]);
      } catch (err) {
        console.error("Error fetching Program data:", err);
        setError("Could not fetch Program data");
        setLoading(false);
      }
    };

    fetchProgramData();
  }, [program?._id]);

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <p className={styles.loading}>Loading...</p>;
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }
  return (
    <>
      <h2 className={`${styles.title} ${styles.h2}`}>Edit epic program</h2>

      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.grid}>
          <div>
            <label className={styles.label}>
              Program Name <span className={styles.required}>*</span>
            </label>
            <input
              type="text"
              name="prog_name"
              value={formData.prog_name}
              onChange={handleChange}
              required
              className={styles.input}
            />
          </div>
          <div>
            <label className={styles.label}>
              Program Type <span className={styles.required}>*</span>
            </label>
            <select
              name="prog_type"
              value={formData.prog_type}
              onChange={handleChange}
              required
              className={styles.select}
            >
              <option value="">Select</option>
              <option value="Training">Training</option>
              <option value="Assessment">Assessment</option>
              <option value="Olympiads">Olympiads</option>
            </select>
          </div>
          <div>
            <label className={styles.label}>
              Description <span className={styles.required}>*</span>
            </label>
            <textarea
              name="prog_description"
              value={formData.prog_description}
              onChange={handleChange}
              required
              className={styles.textarea}
            ></textarea>
          </div>
          <div>
            <label className={styles.label}>
              Duration (in weeks) <span className={styles.required}>*</span>
            </label>
            <input
              type="number"
              name="prog_duration"
              value={formData.prog_duration}
              onChange={handleChange}
              required
              className={styles.input}
            />
          </div>
          <div>
            <label className={styles.label}>
              Program Level <span className={styles.required}>*</span>
            </label>
            <select
              name="level"
              value={formData.level}
              onChange={handleChange}
              required
              className={styles.select}
            >
              <option value="">Select</option>
              <option value="Beginner">Beginner</option>
              <option value="Intermediate">Intermediate</option>
              <option value="Advanced">Advanced</option>
            </select>
          </div>
          <div>
            <label className={styles.label}>
              Program Fee <span className={styles.required}>*</span>
            </label>
            <input
              type="number"
              name="prog_fee"
              value={formData.prog_fee}
              onChange={handleChange}
              required
              className={styles.input}
            />
          </div>
          <div>
            <label className={styles.label}>
              Start Date <span className={styles.required}>*</span>
            </label>
            <input
              type="date"
              name="start_date"
              value={formData.start_date}
              onChange={handleChange}
              required
              className={styles.input}
            />
          </div>
          <div>
            <label className={styles.label}>
              End Date <span className={styles.required}>*</span>
            </label>
            <input
              type="date"
              name="end_date"
              value={formData.end_date}
              onChange={handleChange}
              required
              className={styles.input}
            />
          </div>
        </div>
        <div className={styles.submitBtnBox}>
          <button type="submit" className={styles.submitBtn}>
            {loading ? "Updating..." : "Update"}
          </button>
        </div>

        <div className={styles.formGroup}>
          {error && (
            <p className={styles.linkGroup} style={{ color: "red" }}>
              {error}
            </p>
          )}

          {successMessage && (
            <p className={styles.linkGroup} style={{ color: "green" }}>
              {successMessage}
            </p>
          )}
        </div>
      </form>
    </>
  );
};

export default EditProgram;
