import React, { useEffect, useState } from "react";
import styles from "./EditRegistration.module.css";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { toggleProgramUpdated } from "../../../../../../store/user/programSlice";

const EditRegistration = () => {
  const { currentUser } = useSelector((state) => state.user);
  const { program } = useSelector((state) => state.program);
  const [studentData, setStudentData] = useState(null); // To hold the student data
  const [programs, setPrograms] = useState([]);
  const [currentProgram, setCurrentProgram] = useState({});
  const [courseFees, setCourseFees] = useState(0);
  // const [tot_fee_paid, settot_fee_paid] = useState(0);
  const [loading, setLoading] = useState(false); // Loading state
  const [detailsOpened, setDetailsOpened] = useState(false);
  // const [pending_amount, setPending_amount] = useState(program && program.pending_amount);
  const [error, setError] = useState(null); // Error state
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    tot_fee_paid: program.tot_fee_paid,
    pending_amount: program.pending_amount,
    paymentTxnId: "",
    remarks: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let remainingAmount = formData.pending_amount;
    let amountPaid = value;
    if (name === "tot_fee_paid") {
      if (amountPaid > program.prog_id.prog_fee) {
        amountPaid = program.prog_id.prog_fee;
      } else if (amountPaid < 0) {
        amountPaid = 0;
      }
      remainingAmount = program.prog_id.prog_fee - amountPaid;
    }

    setFormData((prev) => ({
      ...prev,
      pending_amount: remainingAmount,
      [name]: amountPaid,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    window.scrollTo(0, 0);

    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/api/updateRegisteredProgram/${program.prog_regis_id}`,
        formData
      );

      console.log("Update Successful:", response.data);
      dispatch(toggleProgramUpdated(true));
      alert("Update Successful");
    } catch (error) {
      console.error(
        "Error updating program registration:",
        error.response.data
      );
      alert("Update Failed");
    } finally {
      setLoading(false);
      dispatch(toggleProgramUpdated(false));
    }
  };

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <p className={`${styles.loading} ${styles.p}`}>Loading...</p>;
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <form className={styles.registrationForm} onSubmit={handleSubmit}>
      <h2 className={styles.h2}>Edit Registration Form</h2>

      {/* Program Registration ID */}
      <div className={styles.grid}>
        <label className={styles.label}>
          <span>Program Registration Id</span>

          <input
            disabled
            className={`${styles.unchangableData} ${styles.input}`}
            type="text"
            value={program.prog_regis_id}
          />
        </label>

        {/* Student Name */}
        <label className={styles.label}>
          <span>Student Name</span>

          <input
            disabled
            className={`${styles.unchangableData} ${styles.input}`}
            type="text"
            value={
              program.student_id?.first_name +
              " " +
              program.student_id?.last_name
            }
          />
        </label>

        {/* Select Program */}
        <label className={styles.label}>
          Select Program
          <input
            disabled
            type="text"
            name="program"
            value={program.prog_id?.prog_name}
            className={`${styles.selectOption} ${styles.input}`}
          />
        </label>
        <label className={styles.label}>
          <button
            type="button"
            onClick={() => setDetailsOpened(!detailsOpened)}
            className={`${styles.detailsButton} ${styles.button}`}
          >
            {detailsOpened ? "Hide details" : "Show details"}
          </button>
        </label>
      </div>

      <div className={styles.container}>
        {detailsOpened ? (
          <ul className={styles.programList}>
            {/* {programs.map((program) => ( */}
            <li key={currentProgram._id} className={styles.programItem}>
              <h2 className={styles.programTitle}>
                {program.prog_id?.prog_name}
              </h2>
              <p className={`${styles.programDetail} ${styles.p}`}>
                Type: {program.prog_id?.prog_type}
              </p>
              <p className={`${styles.programDetail} ${styles.p}`}>
                Description: {program.prog_id?.prog_description}
              </p>
              <p className={`${styles.programDetail} ${styles.p}`}>
                Duration: {program.prog_id?.prog_duration} days
              </p>
              <p className={`${styles.programDetail} ${styles.p}`}>
                Level: {program.prog_id?.level}
              </p>
              <p
                className={`${styles.programDetail} ${styles.fee} ${styles.p}`}
              >
                Fee: {" " + program.prog_id?.prog_fee + " "} USD
              </p>
              <p
                className={`${styles.programDetail} ${styles.dates} ${styles.p}`}
              >
                Start Date:{" "}
                {new Date(program.prog_id1?.start_date).toLocaleDateString()}
              </p>
              <p
                className={`${styles.programDetail} ${styles.dates} ${styles.p}`}
              >
                End Date:{" "}
                {new Date(program.prog_id?.end_date).toLocaleDateString()}
              </p>
            </li>
            {/* ))} */}
          </ul>
        ) : (
          <p className={`${styles.selectAProgram} ${styles.p}`}></p>
        )}
      </div>

      {/* Program Details (Start Date, End Date, Status, Fee) */}
      <div className={styles.grid}>
        <label className={styles.label}>
          <span>Program Start Date</span>

          <input
            disabled
            type="text"
            className={`${styles.unchangableData} ${styles.input}`}
            value={
              program.prog_id?.start_date
                ? new Date(program.prog_id?.start_date).toLocaleDateString()
                : ""
            }
          />
        </label>

        <label className={styles.label}>
          <span>Program End Date</span>
          <input
            disabled
            type="text"
            className={`${styles.unchangableData} ${styles.input}`}
            value={
              program.prog_id?.end_date
                ? new Date(program.prog_id?.end_date).toLocaleDateString()
                : ""
            }
          />
        </label>

        <label className={styles.label}>
          Program Status
          <input
            disabled
            className={`${styles.unchangableData} ${styles.input}`}
            type="text"
            name="prog_status"
            value={program?.prog_status}
          />
        </label>

        <label className={styles.label}>
          <span>Program Fee</span>

          <input
            disabled
            type="text"
            name="prog_fee"
            className={`${styles.unchangableData} ${styles.input}`}
            value={
              program.prog_id?.prog_fee ? "Rs." + program.prog_id?.prog_fee : ""
            }
            onChange={() => {
              setCourseFees(
                program.prog_id.prog_fee !== undefined
                  ? program.prog_id?.prog_fee
                  : 0
              );
              handleInputChange();
            }}
          />
        </label>
      </div>

      {/* Payment Details */}
      <h3 className={styles.h3}>Make Payment</h3>

      <div className={styles.grid}>
        <div>
          <label className={styles.label}>
            Paid Fee Amount <span className={styles.required}>*</span>
            <input
              disabled={program.pending_amount === 0}
              required
              type="number"
              name="tot_fee_paid"
              value={formData.tot_fee_paid}
              onChange={handleInputChange}
              className={`${styles.feesLabel} ${styles.input}`}
            />
          </label>

          <label className={styles.label}>
            Pending Fee Amount
            <input
              disabled
              type="number"
              name="pending_amount"
              value={formData.pending_amount}
              className={`${styles.feesLabel} ${styles.input}`}
            />
          </label>

          <label className={styles.label}>
            Payment Txn ID <span className={styles.required}>*</span>
            <input
              disabled={program?.pending_amount === 0}
              required
              type="text"
              name="paymentTxnId"
              value={formData.paymentTxnId}
              onChange={handleInputChange}
              className={`${styles.feesLabel} ${styles.input}`}
            />
          </label>
        </div>
        <div className={styles.qrContainer}>
          <img src="/qr/qr.png" alt="qr code" className={styles.qrCode} />
        </div>
      </div>
      <label className={styles.label}>
        Note (if Any)
        <textarea
          disabled={program?.pending_amount === 0}
          name="remarks"
          value={formData.remarks}
          onChange={handleInputChange}
          className={styles.textarea}
        />
      </label>

      {program.pending_amount === 0 && (
        <p className={`${styles.dues} ${styles.p} ${styles.feesClear} `}>
          Your all dues are clear.
        </p>
      )}

      {program.pending_amount !== 0 && (
        <div className={styles.buttons}>
          <button
            disabled={program.pending_amount === 0}
            className={styles.button}
            type="submit"
          >
            Update
          </button>
        </div>
      )}
    </form>
  );
};

export default EditRegistration;
