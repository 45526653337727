import React from 'react'
import Typography from "@mui/material/Typography";


const TillClass10 = () => {
    return (
        <div style={{ marginBottom: '1rem' }}>
            <div style={{ width: '100%', height: 'auto', borderRadius: '5px' }}>
                <Typography variant="h5" gutterBottom style={{ fontSize: '2rem', color: 'teal' }}>
                    Secondary (9th -10th) Curious Minds - Foundations for Success
                </Typography>
                <Typography variant="h6" style={{ listStyleType: 'disc', fontSize: '1.8rem', paddingLeft: '20px' }}>
                    <span style={{ color: 'darkorange' }}>Introduction to Information Technology and its scope</span>
                </Typography>
                <ul style={{ listStyleType: 'disc', paddingLeft: '40px' }}>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Introduction to IT</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Key areas of IT: Software Development, Networking, Data Management, Cyber Security</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Scope of IT: Education, Healthcare, Business and Commerce, Government, Entertainment, Communication</li>
                </ul>
                <Typography variant="h6" style={{ listStyleType: 'disc', fontSize: '1.8rem', paddingLeft: '20px' }}>
                    <span style={{ color: 'darkorange' }}>MS Office (Word, Excel & PowerPoint) with practical & projects</span>
                </Typography>
                <ul style={{ listStyleType: 'disc', paddingLeft: '40px' }}>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Learning of MS Office</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Advanced features and its real-world uses</li>
                </ul>
                <Typography variant="h6" style={{ listStyleType: 'disc', fontSize: '1.8rem', paddingLeft: '20px' }}>
                    <span style={{ color: 'darkorange' }}>Networking & Internet</span>
                </Typography>
                <ul style={{ listStyleType: 'disc', paddingLeft: '40px' }}>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; What is networking in terms of IT</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Different types of networking: PAN, LAN, MAN, WAN</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Components of network</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; What is the Internet?</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Brief history of the Internet</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Key milestones in the development of the Internet</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Browsing the Internet: Web browsers, Basic browser functions</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Using a Web Browser: URL, Web Pages, search engines, bookmarking</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Uses of the Internet: Social Networking, Online Shopping, Education, Research, Gaming, Banking & Finance</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Internet safety & ethics</li>
                </ul>
                <Typography variant="h6" style={{ listStyleType: 'disc', fontSize: '1.8rem', paddingLeft: '20px' }}>
                    <span style={{ color: 'darkorange' }}>Cyber Security & Cyber Ethics with practical</span>
                </Typography>
                <ul style={{ listStyleType: 'disc', paddingLeft: '40px' }}>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; What is Cyber Security?</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Importance of protecting information online</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Common cyber threats: viruses, malware, phishing, hacking</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Understanding the internet and how data travels</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Safe Internet Practices: Recognizing secure websites, avoiding suspicious links and downloads</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Responsible Online Behaviour</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Practical Activities</li>
                </ul>
                <Typography variant="h6" style={{ listStyleType: 'disc', fontSize: '1.8rem', paddingLeft: '20px' }}>
                    <span style={{ color: 'darkorange' }}>Introduction to programming languages, Generations & types of computer languages</span>
                </Typography>
                <ul style={{ listStyleType: 'disc', paddingLeft: '40px' }}>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Understanding what computer programming language is</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Importance of programming in the modern world</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Generations & Types of Programming Languages: First to Fifth Generations</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Different programming languages available: Low-Level Languages, Assembly Language, High-Level</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Programming language concepts: Syntax, Semantics, Variables, Data Types, Control Structures</li>
                </ul>
                <Typography variant="h6" style={{ listStyleType: 'disc', fontSize: '1.8rem', paddingLeft: '20px' }}>
                    <span style={{ color: 'darkorange' }}>Python Programming with practical</span>
                </Typography>
                <ul style={{ listStyleType: 'disc', paddingLeft: '40px' }}>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Introduction to Python: history, features, and installation</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Python IDEs: IDLE, PyCharm, VS Code</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Basics of Python Programming: control structures, functions, strings, file handling</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Exception Handling: try-except blocks</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Working with Databases: SQLite, SQL</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Advanced Topics and Projects: Introduction to machine learning (basics)</li>
                </ul>
                <Typography variant="h6" style={{ listStyleType: 'disc', fontSize: '1.8rem', paddingLeft: '20px' }}>
                    <span style={{ color: 'darkorange' }}>Introduction to Web Technology & Client/Server Architecture</span>
                </Typography>
                <ul style={{ listStyleType: 'disc', paddingLeft: '40px' }}>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Web Technology Meaning</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; www (world wide web)</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Client server model</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Introduction to Web Development languages</li>
                </ul>
                <Typography variant="h6" style={{ listStyleType: 'disc', fontSize: '1.8rem', paddingLeft: '20px' }}>
                    <span style={{ color: 'darkorange' }}>HTML Programming & CSS</span>
                </Typography>
                <ul style={{ listStyleType: 'disc', paddingLeft: '40px' }}>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Introduction to HTML</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; HTML Elements and Attributes</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; HTML Forms and Input</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; HTML Tables</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Introduction to CSS: syntax, structure, and types (Inline, Internal, External)</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; CSS Selectors and Properties</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Box Model and Layout</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Responsive Design with CSS</li>
                </ul>
                <Typography variant="h6" style={{ listStyleType: 'disc', fontSize: '1.8rem', paddingLeft: '20px' }}>
                    <span style={{ color: 'darkorange' }}>Introduction to DBMS (Data Base Management System)</span>
                </Typography>
                <ul style={{ listStyleType: 'disc', paddingLeft: '40px' }}>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; What is a Database and its need in computer programming</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Different types of Databases available</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Introduction to MySQL</li>
                </ul>
                <Typography variant="h6" style={{ listStyleType: 'disc', fontSize: '1.8rem', paddingLeft: '20px' }}>
                    <span style={{ color: 'darkorange' }}>Introduction to AI & Its Uses</span>
                </Typography>
                <ul style={{ listStyleType: 'disc', paddingLeft: '40px' }}>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; What Artificial Intelligence (AI) and its goals are in today’s society</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Types of AI: Narrow, General, Super Intelligent</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Uses of AI and Tools used to do simple tasks using AI</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Introduction to Catgut</li>
                </ul>
                <Typography variant="h6" style={{ listStyleType: 'disc', fontSize: '1.8rem', paddingLeft: '20px' }}>
                    <span style={{ color: 'darkorange' }}>Basics of Cloud Computing</span>
                </Typography>
                <ul style={{ listStyleType: 'disc', paddingLeft: '40px' }}>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Introduction to Cloud Computing and its significance</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Key characteristics of AI</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Different service models of Cloud Computing</li>
                </ul>
            </div>
        </div>
    )
}

export default TillClass10;