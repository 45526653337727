import React, { useEffect, useState } from "react";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { useSelector } from "react-redux";
import styles from "./CertificateEditor.module.css";
import axios from "axios";

const CertificateEditor = () => {
  // const [name, setName] = useState("Your Name Here");

  const [studentData, setStudentData] = useState(null);
  const { currentUser } = useSelector((state) => state.user);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  // Function to download the certificate as a PDF
  const downloadCertificateAsPDF = () => {
    const certificateElement = document.getElementById("certificate");

    html2canvas(certificateElement, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      // Calculate the aspect ratio of the canvas
      const pdf = new jsPDF({
        orientation: "landscape",
        unit: "px",
        format: [canvas.width, canvas.height], // Set to match the canvas size
      });

      // Add the image to the PDF (at 0, 0 starting point)
      pdf.addImage(imgData, "PNG", 0, 0, canvas.width, canvas.height);

      // Save the PDF
      pdf.save("certificate.pdf");
    });
  };

  const downloadCertificateAsImage = () => {
    const certificateElement = document.getElementById("certificate");

    html2canvas(certificateElement, {
      scale: 2, // Increase resolution
      useCORS: true, // If the image is hosted on a different domain, this is necessary
    }).then((canvas) => {
      const link = document.createElement("a");
      link.href = canvas.toDataURL("image/png");
      link.download = "certificate.png";
      link.click();
    });
  };

  useEffect(() => {
    const fetchStudentData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/students/${currentUser.user._id}`
        );
        const data = response.data[0];
        setStudentData(data); // Set the student data
        setLoading(false); // Data is loaded
        console.log("Student data:", response.data[0]);
      } catch (err) {
        console.error("Error fetching student data:", err);
        setError("Could not fetch student data");
        setLoading(false);
      }
    };

    fetchStudentData();
  }, [currentUser]);

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <p className={styles.loading}>Loading...</p>;
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className={styles.container}>
      {/* Certificate display */}
      <button
        className={`${styles.button} ${styles.pdf}`}
        onClick={downloadCertificateAsPDF}
      >
        Download as PDF
      </button>
      <button
        className={`${styles.button} ${styles.image}`}
        onClick={downloadCertificateAsImage}
      >
        Download as Image
      </button>
      <div
        id="certificate"
        style={{
          position: "relative",
          width: "800px",
          height: "600px",
          backgroundImage: "url(/CertificateTemp.jpeg)", // Your certificate image URL here
          backgroundSize: "cover",
          margin: "20px auto",
        }}
        className={styles.laptopCertificate}
      >
        {/* Name overlay */}
        <div
          style={{
            position: "absolute",
            top: "52.5%", // Adjust based on where you want the name
            left: "52%",
            transform: "translate(-50%, -50%)",
            fontSize: "36px",
            fontWeight: "bold",
            color: "#000", // Customize text color based on the image
          }}
        >
          {studentData.first_name + " " + studentData.last_name}
        </div>
      </div>
      {/* Just for mobile and tablets for viewing purpose only */}
      <div
        style={{
          backgroundImage: "url(/CertificateTemp.jpeg)",
        }}
        className={styles.certificate}
      >
        <div className={styles.certificateName}>
          {studentData.first_name + " " + studentData.last_name}
        </div>
      </div>
    </div>
  );
};

export default CertificateEditor;
