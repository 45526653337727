import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./CardSlider.css";
import Card3 from "../cards/Card3";

const WebDevelopmentSlider = ({ heading }) => {
  // if(window.innerWidth<='1200' && window.innerWidth>='800'){
  //   setSlides(2)
  // }
  // else if(window.innerWidth<='800'){
  //   setSlides(1)
  // }

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    centerPadding: "10px",
    responsive: [
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 660,
        settings: {
          slidesToShow: 1,
        }
      }
    ],
  };

  return (
    <>
      <Slider {...settings} className="card-slider">
        <div className="slider-item">
          <Card3
            src={"assets/nurturing.jpg"}
            // title={"Full-Stack Development:"}
            text={" Nurturing Future Innovation."}
          />
        </div>
        <div className="slider-item">
          <Card3
            src={"assets/moving.jpg"}
            // title={"CMS Development:"}
            text={"Moving Technology Accessible to everyone"}
          />
        </div>
        <div className="slider-item">
          <Card3
            src={"assets/future-tech.jpg"}
            // title={"E-Commerce Development:"}
            text={"Empowering Future with Technology"}
          />
        </div>
        
      </Slider>
    </>
  );
};

export default WebDevelopmentSlider;
