import React, { useEffect, useState } from "react";
import styles from "./EpicPrograms.module.css";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  toggleProgramModal,
  updateProgram,
} from "../../../../../store/user/programSlice";
import Dropdown from "../dropdown/Dropdown";
import {
  setProgramOpened,
  setSchoolProfileOpened,
  updateProgramEdit,
  updateSchool,
} from "../../../../../store/user/adminSlice";
import SchoolProfile from "../../../school/subpages/profile/SchoolProfile";
import AddProgramForm from "./AddProgram/AddProgramForm";
import EditProgram from "./EditProgram/EditProgram";

const EpicPrograms = () => {
  const { currentUser } = useSelector((state) => state.user);
  // const { programUpdated } = useSelector((state) => state.program);
  const [loading, setLoading] = useState(true);
  const [addProgramOpened, setAddProgramOpened] = useState(false);
  const dispatch = useDispatch();
  const [error, setError] = useState("");

  const [allPrograms, setAllPrograms] = useState([]);

  const { programOpened } = useSelector((state) => state.admin);

  const fetchProgramsData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/getAllPrograms`
      );
      const programs = response.data;

      console.log("All programs", programs);

      setAllPrograms(programs);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching programs data:", err);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProgramsData();
  }, [currentUser]);

  const handleOpenForm = (program) => {
    console.log("Current program: ", program);
    dispatch(updateProgramEdit(program));
    dispatch(setProgramOpened(true));
    window.scrollTo(0, 0);
  };

  const handelDeleteProgram = async (program) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/deleteProgram/${program._id}`
      );

      console.log(response);
      if (response.status === 200) {
        fetchProgramsData();
        alert("Program deleted successfully");
      }
    } catch (error) {
      console.error("Failed to delete Program: ", error);
      alert("Failed to delete Program");
    }
  };

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <p className={styles.loading}>Loading...</p>;
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      {!programOpened ? (
        <div className={styles.studentPage}>
          <h2 className={styles.h2}>All EPIC Programs</h2>

          <div className={styles.addContainer}>
            <button
              className={styles.addButton}
              onClick={() => setAddProgramOpened((prev) => !prev)}
            >
              {addProgramOpened ? "Close form" : "Add new program"}
            </button>
          </div>

          {addProgramOpened && (
            <AddProgramForm fetchProgramsData={fetchProgramsData} />
          )}
          <div className={styles.tableContainer}>
            {allPrograms.length > 0 ? (
              <table className={styles.programsTable}>
                <thead>
                  <tr>
                    <th className={styles.th}>Program Name</th>
                    <th className={styles.th}>Program Type</th>
                    <th className={styles.th}>
                      Program Duration
                      <p>( in weeks)</p>
                    </th>
                    <th className={styles.th}>Program Fees (in rupees)</th>
                    <th className={styles.th}>Update</th>
                    <th className={styles.th}>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {allPrograms.map((program, index) => {
                    // const key = Object.keys(program);
                    // const studentName = key[0];
                    // const studentProgram = program[studentName];
                    return (
                      <tr key={index}>
                        <td className={styles.td}>{program.prog_name}</td>
                        <td className={styles.td}>{program.prog_type}</td>
                        <td className={styles.td}>{program.prog_duration}</td>
                        <td className={styles.td}>{program.prog_fee}</td>
                        <td className={styles.td}>
                          <span
                            className={`${styles.details} ${styles.updateButton}`}
                            onClick={() => handleOpenForm(program)}
                          >
                            Update
                          </span>
                        </td>
                        <td className={styles.td}>
                          <span
                            className={`${styles.details} ${styles.deleteButton}`}
                            onClick={() => handelDeleteProgram(program)}
                          >
                            Delete
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <p className={styles.p}>
                Sorry!! No Record Found for this location.
              </p>
            )}
          </div>
        </div>
      ) : (
        <>
          <button
            className={styles.goBackButton}
            onClick={() => dispatch(setProgramOpened(false))}
          >
            &laquo; Go Back
          </button>
          <EditProgram fetchProgramsData={fetchProgramsData} />
        </>
      )}
    </>
  );
};
export default EpicPrograms;
