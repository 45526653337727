import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpen: false,
};

const certificateSlice = createSlice({
  name: "certificate",
  initialState,
  reducers: {
    toggleCertificateModal: (state) => {
      state.isOpen = !state.isOpen;
    },
  },
});

export const { toggleCertificateModal } = certificateSlice.actions;

export default certificateSlice.reducer;
