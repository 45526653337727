import React from 'react'
// import { NavLink } from "react-router-dom";

const Card3 = ({ src, alt, text }) => {
  return (
    <>
      <div className="card3">
        <div className="card3-image-container">
          <img src={src} alt={alt} />
        </div>
        <div className="card3-content">
          {/* <h3>{title}</h3> */}
          <p>
            {text}
          </p>
          {/* <NavLink className={'cta'} to={'/contact'}>Contact Us</NavLink> */}
        </div>
      </div>
    </>
  )
}

export default Card3