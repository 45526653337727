import React from 'react'
import Typography from "@mui/material/Typography";

const TillClass5 = () => {
    return (
        <div style={{ marginBottom: '1rem' }}>
            <div style={{ width: '100%', height: 'auto', borderRadius: '5px' }}>
                <Typography variant="h5" gutterBottom style={{ fontSize: '2rem', color: 'teal' }}>
                    A. PRIMARY (Class 1st - 5th) - Junior Pioneers: Learn with Fun
                </Typography>
                <Typography variant="h6" style={{ listStyleType: 'disc', fontSize: '1.8rem', paddingLeft: '20px' }}><span style={{ color: 'darkorange' }}>Introduction to Computers</span></Typography>
                <ul style={{ listStyleType: 'disc', paddingLeft: '40px' }}>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Definition and basic understanding.</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Importance of computers in daily life.</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Basic parts of a computer & function of each part.</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Different types of computers.</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; What is Hardware & Computer Software? Understanding the difference.</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Detailed understanding of Hardware & Software.</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Introduction to Input & Output devices: Definition and basic understanding & Different types of Input & Output devices.</li>
                </ul>
                <Typography variant="h6" style={{ listStyleType: 'disc', fontSize: '1.8rem', paddingLeft: '20px' }}><span style={{ color: 'darkorange' }}>Operating Systems</span></Typography>
                <ul style={{ listStyleType: 'disc', paddingLeft: '40px' }}>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Introduction to Operating System & Different types of OS available today (e.g., Windows).</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Basic functions of an operating system.</li>
                </ul>
                <Typography variant="h6" style={{ listStyleType: 'disc', fontSize: '1.8rem', paddingLeft: '20px' }}><span style={{ color: 'darkorange' }}>Uses of Computers (Drawing, Gaming, Chatting)</span></Typography>
                <ul style={{ listStyleType: 'disc', paddingLeft: '40px' }}>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Using simple drawing software (MS Paint).</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Basic tools: brush, shapes, colors.</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Introduction to Tux Paint for creative drawing.</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Understanding the concept of chatting with supervision.</li>
                </ul>
                <Typography variant="h6" style={{ listStyleType: 'disc', fontSize: '1.8rem', paddingLeft: '20px' }}><span style={{ color: 'darkorange' }}>Introduction to Drawing Software and Having Fun with Drawing using MS Paint, Tux Paint</span></Typography>
                <ul style={{ listStyleType: 'disc', paddingLeft: '40px' }}>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Drawing features in MS Paint and Tux Paint.</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Using MS Paint and Tux Paint for creating detailed drawings.</li>
                </ul>
                <Typography variant="h6" style={{ listStyleType: 'disc', fontSize: '1.8rem', paddingLeft: '20px' }}><span style={{ color: 'darkorange' }}>Typing Skills using MS Word and Basic Word Processing</span></Typography>
                <ul style={{ listStyleType: 'disc', paddingLeft: '40px' }}>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Practicing typing skills using MS Word.</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Proficient use of MS Word for creating, saving, editing, and printing documents.</li>
                </ul>
                <Typography variant="h6" style={{ listStyleType: 'disc', fontSize: '1.8rem', paddingLeft: '20px' }}><span style={{ color: 'darkorange' }}>Have Fun by Making Presentations using PowerPoint</span></Typography>
                <ul style={{ listStyleType: 'disc', paddingLeft: '40px' }}>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Introduction to MS PowerPoint & why we need to create presentations.</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Creating simple presentations with text and images.</li>
                </ul>
                <Typography variant="h6" style={{ listStyleType: 'disc', fontSize: '1.8rem', paddingLeft: '20px' }}><span style={{ color: 'darkorange' }}>Basic Internet Introduction and Demo. Browsing and Using the Internet</span></Typography>
                <ul style={{ listStyleType: 'disc', paddingLeft: '40px' }}>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Understanding what the internet is.</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; What is a browser and different types of browsers available to use the internet.</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Safe browsing practices and basic online research.</li>
                </ul>
                <Typography variant="h6" style={{ listStyleType: 'disc', fontSize: '1.8rem', paddingLeft: '20px' }}><span style={{ color: 'darkorange' }}>Basic Programming Concepts and Block-Based Programming</span></Typography>
                <ul style={{ listStyleType: 'disc', paddingLeft: '40px' }}>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; What is programming in computers and what is programming languages.</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Different programming languages.</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Introduction to programming concepts (conditions, looping, switch, etc.)</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Basic programs using Python.</li>
                </ul>
            </div>
        </div>
    )
}

export default TillClass5
