import React, { useEffect, useState } from "react";
import styles from "./RegisteredEpicPrograms.module.css";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  toggleProgramModal,
  updateProgram,
} from "../../../../../store/user/programSlice";

const RegisteredEpicPrograms = () => {
  const { currentUser } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [registeredPrograms, setRegisteredPrograms] = useState([
    {
      program: "COP (Computer Outreach Program)",
      status: "Completed",
      details: "view details",
    },
    { program: "Junior Olympiads", status: "Pending", details: "view details" },
  ]);

  const [myRegisteredPrograms, setMyRegisteredPrograms] = useState([]);

  useEffect(() => {
    const fetchStudentData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/students/${currentUser.user._id}`
        );
        const data = response.data[0];
        const response2 = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/getRegisteredPrograms/${data._id}`
        );
        const data2 = response2.data.programs;
        console.log("Data", data2);
        setMyRegisteredPrograms(data2);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching student data:", err);
        setLoading(false);
      }
    };

    fetchStudentData();
  }, [currentUser]);

  const handleOpenForm = (program) => {
    console.log("Current program: ", program);
    dispatch(updateProgram(program));
    dispatch(toggleProgramModal());
  };

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <p className={styles.loading}>Loading...</p>;
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className={styles.studentPage}>
      <h2 className={styles.h2}>Registered EPIC Programs</h2>
      <div className={styles.tableContainer}>
        {myRegisteredPrograms.length > 0 ? (
          <table className={styles.programsTable}>
            <thead>
              <tr>
                <th className={styles.th}>Program</th>
                <th className={styles.th}>Status</th>
                <th className={styles.th}>Details</th>
              </tr>
            </thead>
            <tbody>
              {myRegisteredPrograms.map((program, index) => (
                <tr key={index}>
                  <td className={styles.td}>{program.prog_id.prog_name}</td>
                  <td className={styles.td}>{program.prog_status}</td>
                  <td className={styles.td}>
                    <span
                      className={styles.details}
                      onClick={() => handleOpenForm(program)}
                    >
                      View details
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className={styles.noProgramsContainer}>
            <p className={styles.p}>
              Sorry!! Not registered to any EPIC Program.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default RegisteredEpicPrograms;
