import Style from "./CarousalCss.module.css";
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { NavLink } from "react-router-dom";

const Carousal = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 3000, // Autoplay speed in milliseconds
    arrows: false,
  };
  return (
    <>
      <div>
        <NavLink className={Style.quote} to={"/contact"}>
          Get a Quote
        </NavLink>
      </div>
      <div className={Style.carousalContainer}>
        <Slider {...settings}>
          <div className={Style.relativeBox}>
            <div className={Style.overlay}></div>

            <h1 className={Style.heading1}>
              Innovate - Educate - Elevate <br />{" "}
              <span className={Style.heading1Span}>
                Transforming Education - Empowering Youth.
              </span>{" "}
            </h1>
            <img src="/homeImages/image1.jpg" className={Style.image} />
          </div>
          <div className={Style.relativeBox}>
            <div className={Style.overlay}></div>

            <h1 className={Style.heading2}>
              Your Journey to Technical Excellence Starts Here.
            </h1>

            <img src="/homeImages/image2.jpg" className={Style.image} />
          </div>
          <div className={Style.relativeBox}>
            <div className={Style.overlay}></div>

            <h1 className={Style.heading3}>
              Inspiring Technical Excellence Across India.
            </h1>

            <img src="/homeImages/image3.jpg" className={Style.image} />
          </div>
        </Slider>
      </div>
    </>
  );
};

export default Carousal;
