import React, { useState } from "react";
import styles from "./StudentRegistrationForm.module.css";
import axios from "axios";

const StudentRegistrationForm = ({ onClose, setIsLoginOpen }) => {
  const [formData, setFormData] = useState({
    user_type: "student",
    first_name: "",
    last_name: "",
    father_name: "",
    mobile_no: "",
    email: "",
    username: "",
    password: "",
    confirmPassword: "",
    twoFactorAuth: false,
    date_of_birth: "",
    std_grade: "",
    schoolName: "",
    schoolType: "Primary",
    schoolAddress: "",
    add_city: "",
    add_dist: "",
    add_state: "",
    add_pin: "",
    gender: "male",
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const validatemobile_no = (mobile) => {
    const mobilePattern = /^[0-9]{10}$/;
    return mobilePattern.test(mobile);
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const validatePasswordStrength = (password) => {
    return password.length >= 8; // Example: Require a minimum of 8 characters
  };

  const sleep = () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        // alert("Registration successful...");
        setFormData({
          user_type: "student",
          first_name: "",
          last_name: "",
          father_name: "",
          mobile_no: "",
          email: "",
          username: "",
          password: "",
          confirmPassword: "",
          twoFactorAuth: false,
          date_of_birth: "",
          std_grade: "",
          schoolName: "",
          schoolType: "Primary",
          schoolAddress: "",
          add_city: "",
          add_dist: "",
          add_state: "",
          add_pin: "",
          gender: "male",
        });
        onClose();
        setIsLoginOpen(true);
      }, 500);
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validatemobile_no(formData.mobile_no)) {
      alert("Please enter a valid 10-digit mobile number.");
      return;
    }
    if (!validateEmail(formData.email)) {
      alert("Please enter a valid email address.");
      return;
    }
    if (!validatePasswordStrength(formData.password)) {
      alert("Password must be at least 8 characters long.");
      return;
    }
    if (formData.password !== formData.confirmPassword) {
      alert("Passwords do not match.");
      return;
    }

    setLoading(true);
    setError("");
    setSuccessMessage("");

    try {
      // Make the request to your backend API
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/registerStudent`,
        formData
      ); // Replace with your backend URL

      console.log("Response:", response);

      if (response.status === 201) {
        setSuccessMessage("User and student registered successfully");
        sleep();
      }
    } catch (error) {
      setError(
        error.response ? error.response.data.error : "Registration failed"
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <h2 className={`${styles.title} ${styles.h2}`}>Student Sign-Up Form</h2>

      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.section}>
          <label className={styles.label}>
            First Name <span className={styles.required}>*</span>
          </label>
          <input
            type="text"
            name="first_name"
            value={formData.first_name}
            onChange={handleChange}
            required
            className={styles.input}
          />
          <label className={styles.label}>
            Last Name <span className={styles.required}>*</span>
          </label>
          <input
            type="text"
            name="last_name"
            value={formData.last_name}
            onChange={handleChange}
            required
            className={styles.input}
          />

          <label className={styles.label}>
            Father Name <span className={styles.required}>*</span>
          </label>
          <input
            type="text"
            name="father_name"
            value={formData.father_name}
            onChange={handleChange}
            required
            className={styles.input}
          />

          <label className={styles.label}>
            Mobile Number <span className={styles.required}>*</span>
          </label>
          <input
            type="tel"
            name="mobile_no"
            value={formData.mobile_no}
            onChange={handleChange}
            required
            pattern="[0-9]{10}"
            title="Please enter a valid 10-digit number"
            className={styles.input}
          />

          <label className={styles.label}>
            Email Address <span className={styles.required}>*</span>
          </label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            className={styles.input}
          />

          <label className={styles.label}>
            Username <span className={styles.required}>*</span>
          </label>
          <input
            type="text"
            name="username"
            value={formData.username}
            onChange={handleChange}
            required
            className={styles.input}
          />
          {/* Implement DB check for unique username */}

          <label className={styles.label}>
            Password <span className={styles.required}>*</span>
          </label>
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
            pattern=".{8,}"
            title="Password must be at least 8 characters long"
            className={styles.input}
          />

          <label className={styles.label}>
            Confirm Password <span className={styles.required}>*</span>
          </label>
          <input
            type="password"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
            required
            className={styles.input}
          />

          <div className={styles.checkboxContainer}>
            <label className={styles.label}>
              Two-Factor Authentication{" "}
              <span className={styles.required}>*</span>
            </label>
            <input
              type="checkbox"
              name="twoFactorAuth"
              onChange={() =>
                setFormData({
                  ...formData,
                  twoFactorAuth: !formData.twoFactorAuth,
                })
              }
              className={styles.input}
            />
          </div>

          <label className={styles.label}>
            Date of Birth <span className={styles.required}>*</span>
          </label>
          <input
            type="date"
            name="date_of_birth"
            value={formData.date_of_birth}
            onChange={handleChange}
            required
            className={styles.input}
          />

          <label className={styles.label}>
            Gender <span className={styles.required}>*</span>
          </label>
          <select
            className={styles.select}
            name="gender"
            value={formData.gender}
            onChange={handleChange}
          >
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">Other</option>
          </select>
        </div>

        <div className={styles.section}>
          <label className={styles.label}>
            Grade <span className={styles.required}>*</span>
          </label>
          <select
            name="std_grade"
            value={formData.std_grade}
            onChange={handleChange}
            required
            className={styles.select}
          >
            <option value="">Select</option>
            <option value="1-5">1-5</option>
            <option value="6-8">6-8</option>
            <option value="9-10">9-10</option>
            <option value="11-12">11-12</option>
          </select>

          <label className={styles.label}>
            School Name <span className={styles.required}>*</span>
          </label>
          <input
            type="text"
            name="schoolName"
            value={formData.schoolName}
            onChange={handleChange}
            required
            className={styles.input}
          />

          <label className={styles.label}>
            School Type <span className={styles.required}>*</span>
          </label>
          <select
            name="schoolType"
            value={formData.schoolType}
            onChange={handleChange}
            required
            className={styles.select}
          >
            <option value="Primary">Primary</option>
            <option value="Secondary">Secondary</option>
            <option value="Higher_Secondary">Higher Secondary</option>
          </select>

          <label className={styles.label}>
            School Address <span className={styles.required}>*</span>
          </label>
          <input
            type="text"
            name="schoolAddress"
            value={formData.schoolAddress}
            onChange={handleChange}
            required
            className={styles.input}
          />

          <div className={styles.grid}>
            <div>
              <label className={styles.label}>
                City <span className={styles.required}>*</span>
              </label>
              <input
                type="text"
                name="add_city"
                value={formData.add_city}
                onChange={handleChange}
                required
                className={styles.input}
              />
            </div>
            <div>
              <label className={styles.label}>
                District <span className={styles.required}>*</span>
              </label>
              <input
                type="text"
                name="add_dist"
                value={formData.add_dist}
                onChange={handleChange}
                required
                className={styles.input}
              />
            </div>
            <div>
              <label className={styles.label}>
                State <span className={styles.required}>*</span>
              </label>
              <input
                type="text"
                name="add_state"
                value={formData.add_state}
                onChange={handleChange}
                required
                className={styles.input}
              />
            </div>
            <div>
              <label className={styles.label}>
                Pin Code <span className={styles.required}>*</span>
              </label>
              <input
                type="text"
                name="add_pin"
                value={formData.add_pin}
                onChange={handleChange}
                required
                className={styles.input}
              />
            </div>
          </div>
        </div>

        {/* <div className={styles.section}>
          <h3>Guardian Information</h3>

                    <label className={styles.label}>

            Father/Mother/Guardian Name{" "}
            <span className={styles.required}>*</span>
          </label>
          <input
            type="text"
            name="guardianName"
            value={formData.guardianName}
            onChange={handleChange}
            required
          />

                    <label className={styles.label}>

            Father/Mother/Guardian Mobile Number{" "}
            <span className={styles.required}>*</span>
          </label>
          <input
            type="tel"
            name="guardiancontact_number"
            value={formData.guardiancontact_number}
            onChange={handleChange}
            required
            pattern="[0-9]{10}"
            title="Please enter a valid 10-digit number"
          />
        </div> */}

        <button type="submit" className={styles.submitBtn}>
          {loading ? "Submitting..." : "Submit"}
        </button>

        <div className={styles.formGroup}>
          {error && (
            <p className={styles.linkGroup} style={{ color: "red" }}>
              {error}
            </p>
          )}

          {successMessage && (
            <p className={styles.linkGroup} style={{ color: "green" }}>
              {successMessage}
            </p>
          )}
        </div>
      </form>
    </>
  );
};

export default StudentRegistrationForm;
