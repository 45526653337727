import React from "react";

const SideNav = () => {
  return (
    <>

      <nav className="sidenav">
        <ul>
          <li>
            <a href="#home-top">
              Top
            </a>
          </li>
          <li>
            <a href="#programs">Programs</a>
          </li>
          <li>
            <a href="#program-objective">Program <br /> Objective</a>
          </li>
          <li>
            <a href="#goals">Achiving Goal</a>
          </li>
          {/* <li>
            <a href="#home-contact-section">Cover Story</a>
          </li> */}
          
        </ul>
      </nav>
    </>
  );
};

export default SideNav;
