import React from "react";
import styles from "./RegisterDialogBox.module.css";
import { RxCross2 } from "react-icons/rx";

const RegisterDialogBox = ({
  setIsRegisterOpen,
  handleOpenSchoolModal,
  handleOpenStudentModal,
  handleOpenAdminModal,
}) => {
  return (
    <>
      <div className={styles.relativeBox}>
        <div className={styles.overlay}></div>
      </div>
      <div className={styles.box}>
        <div onClick={() => setIsRegisterOpen(false)}>
          <RxCross2 className={styles.cross} />
        </div>
        <div className={styles.content}>
          <p className={styles.paragraph}>Choose Sign Up Option</p>
          <div className={styles.buttonGroup}>
            <button
              className={`${styles.button} ${styles.signUp}`}
              onClick={handleOpenSchoolModal}
            >
              School Sign Up
            </button>
            <button
              className={`${styles.button} ${styles.signUp}`}
              onClick={handleOpenStudentModal}
            >
              Student Sign Up
            </button>
            <button
              className={`${styles.button} ${styles.signUp}`}
              onClick={handleOpenAdminModal}
            >
              Admin Sign Up
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterDialogBox;
