import React, { useState } from "react";
import styles from "./LoginForm.module.css";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  signInFailure,
  signInStart,
  signInSuccess,
} from "../../store/user/userSlice";
import { useNavigate } from "react-router-dom";

const LoginForm = ({ setIsRegisterOpen, handleCloseLoginModal }) => {
  const [userType, setUserType] = useState("student");
  const [identifier, setIdentifier] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const { loading } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSignUp = () => {
    setIsRegisterOpen(true);
    handleCloseLoginModal();
  };

  const sleep = () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        handleCloseLoginModal();
      }, 500);
    });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setError("");
    setMessage("");

    try {
      dispatch(signInStart());

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/login`,
        {
          identifier,
          password,
          userType,
        }
      );
      // const data = await response.json();

      if (response.status === 200) {
        setMessage("Login successful!");
        // Store the token in local storage or context for further use
        localStorage.setItem("token", response.data.token);
        // Redirect to dashboard or another protected route
        console.log("Logged in", response);
        // alert("Login successful!");

        dispatch(signInSuccess(response.data));
        console.log(response.data);
        if (response.data.user_type === "student") {
          navigate("/student");
        } else if (response.data.user_type === "school") {
          navigate("/school");
        } else {
          navigate("/admin");
        }
        // handleCloseLoginModal();
        sleep();
      }
    } catch (err) {
      setError("Login failed. Please try again.");
      dispatch(signInFailure(err));
    }
  };
  const handleUserTypeChange = (e) => {
    setUserType(e.target.value);
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.h1}>Login</h1>

      <form className={styles.form} onSubmit={handleLogin}>
        <div className={styles.radioGroup}>
          <div className={styles.radioLabel}>
            <span className={styles.span}>Login as: </span>
          </div>
          <div className={styles.radioLabel}>
            <input
              type="radio"
              name="loginAs"
              value="student"
              required
              onChange={handleUserTypeChange}
            />
            <span className={styles.span}>Student</span>
          </div>
          <div className={styles.radioLabel}>
            <input
              type="radio"
              name="loginAs"
              value="school"
              onChange={handleUserTypeChange}
            />
            <span className={styles.span}>School</span>
          </div>
          <div className={styles.radioLabel}>
            <input
              type="radio"
              name="loginAs"
              value="admin"
              onChange={handleUserTypeChange}
            />
            <span className={styles.span}>Admin</span>
          </div>
        </div>

        <div className={styles.formGroup}>
          <label className={styles.label} htmlFor="identifier">
            User Id/Email/Mobile:
          </label>
          <input
            type="text"
            id="identifier"
            className={styles.inputField}
            value={identifier}
            onChange={(e) => setIdentifier(e.target.value)}
            required
          />
        </div>

        <div className={styles.formGroup}>
          <label className={styles.label} htmlFor="password">
            Password:
          </label>
          <input
            type="password"
            id="password"
            className={styles.inputField}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div className={styles.formGroup}>
          {error && (
            <p className={styles.linkGroup} style={{ color: "red" }}>
              {error}
            </p>
          )}

          {message && (
            <p className={styles.linkGroup} style={{ color: "green" }}>
              {message}
            </p>
          )}
        </div>

        <div className={styles.linkGroup}>
          <span>Forgot Password? </span>
          <a href="#" className={styles.link}>
            Click Here
          </a>
        </div>

        <div className={styles.buttonContainer}>
          <button type="submit" className={styles.submitButton}>
            {loading === true ? "Logging in..." : "Log In"}
          </button>
        </div>

        <div className={styles.linkGroup}>
          <a href="#" className={styles.link} onClick={handleSignUp}>
            Click here
          </a>
          <span> if Not Already 'Signed Up'</span>
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
