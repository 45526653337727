import React, { useEffect, useState } from "react";
import styles from "./StudentProfiles.module.css";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  toggleProgramModal,
  updateProgram,
} from "../../../../../store/user/programSlice";
import Dropdown from "../dropdown/Dropdown";
import {
  setStudentProfileOpened,
  updateStudent,
} from "../../../../../store/user/adminSlice";
import StudentProfile from "../../../student/subpages/profile/StudentProfile";

const StudentProfiles = () => {
  const { currentUser } = useSelector((state) => state.user);
  // const { programUpdated } = useSelector((state) => state.program);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [error, setError] = useState("");

  const [allStudents, setAllStudents] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState(allStudents);
  const [allStates, setAllStates] = useState([]);
  const [allDistricts, setAllDistricts] = useState([]);
  const [allCities, setAllCities] = useState([]);
  const [allSchools, setAllSchools] = useState([]);

  const [selectedState, setSelectedState] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedSchool, setSelectedSchool] = useState("");
  const { studentProfileOpened } = useSelector((state) => state.admin);

  const fetchStudentData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/getAllstudents`
      );
      const students = response.data;
      const states = [...new Set(students.map((student) => student.add_state))];
      const schools = [
        ...new Set(students.map((student) => student.schoolName)),
      ];

      console.log("All students", students);

      setAllStudents(students);
      setFilteredStudents(students);
      setAllStates(states);
      // setAllSchools(schools);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching student data:", err);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStudentData();
  }, [currentUser]);

  useEffect(() => {
    setSelectedDistrict("");
    setSelectedCity("");
    setSelectedSchool("");
    if (selectedState === "") {
      setFilteredStudents(allStudents);
      setAllDistricts([]);
      setAllCities([]);
      setAllSchools([]);
    } else {
      const currStateStudents = allStudents.filter(
        (student) => student.add_state === selectedState
      );
      const allDistricts = [
        ...new Set(currStateStudents.map((student) => student.add_dist)),
      ];
      setAllDistricts(allDistricts);
      setFilteredStudents(currStateStudents);
    }
  }, [selectedState]);

  useEffect(() => {
    if (selectedState === "") return;
    setSelectedCity("");
    setSelectedSchool("");
    if (selectedDistrict === "") {
      const currStateStudents = allStudents.filter(
        (student) => student.add_state === selectedState
      );
      setFilteredStudents(currStateStudents);
      setAllCities([]);
      setAllSchools([]);
      console.log(currStateStudents);
      // alert("Empty one");
    } else {
      const currDistrictStudents = allStudents.filter(
        (student) => student.add_dist === selectedDistrict
      );
      const allCities = [
        ...new Set(currDistrictStudents.map((student) => student.add_city)),
      ];
      setAllCities(allCities);
      setFilteredStudents(currDistrictStudents);
    }
  }, [selectedDistrict]);

  useEffect(() => {
    if (selectedDistrict === "") return;
    setSelectedSchool("");
    if (selectedCity === "") {
      const currDistrictStudents = allStudents.filter(
        (student) => student.add_dist === selectedDistrict
      );
      setFilteredStudents(currDistrictStudents);
      setAllSchools([]);
    } else {
      const currCityStudents = allStudents.filter(
        (student) => student.add_city === selectedCity
      );
      const currCitySchools = [
        ...new Set(currCityStudents.map((student) => student.schoolName)),
      ];
      setFilteredStudents(currCityStudents);
      setAllSchools(currCitySchools);
    }
  }, [selectedCity]);

  useEffect(() => {
    if (selectedCity === "") return;
    if (selectedSchool === "") {
      const currCityStudents = allStudents.filter(
        (student) => student.add_city === selectedCity
      );
      setFilteredStudents(currCityStudents);
    } else {
      const currSchoolStudents = allStudents.filter(
        (student) =>
          student.schoolName === selectedSchool &&
          student.add_city === selectedCity
      );
      setFilteredStudents(currSchoolStudents);
    }
  }, [selectedSchool]);

  const handleOpenForm = (student) => {
    console.log("Current student: ", student);
    dispatch(updateStudent(student));
    dispatch(setStudentProfileOpened(true));
    window.scrollTo(0, 0);
  };

  const handelDeleteStudent = async (student) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/deleteStudent/${student._id}/${student.user_id._id}`
      );
      // if (!response.ok) {
      //   alert("Failed to delete student");
      //   return;
      // }
      // const data = await response.json();
      // alert("Student deleted successfully");

      console.log(response);
      if (response.status === 200) {
        fetchStudentData();
        alert("Student deleted successfully");
      }
    } catch (error) {
      console.error("Failed to delete student: ", error);
      alert("Failed to delete student");
    }
  };

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <p className={styles.loading}>Loading...</p>;
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      {!studentProfileOpened ? (
        <div className={styles.studentPage}>
          <h2 className={styles.h2}>All Students Data</h2>
          <div className={styles.selectContainer}>
            <Dropdown
              category={"State"}
              options={allStates}
              selectedOption={selectedState}
              setSelectedOption={setSelectedState}
            />
            <Dropdown
              category={"District"}
              options={allDistricts}
              selectedOption={selectedDistrict}
              setSelectedOption={setSelectedDistrict}
            />
            <Dropdown
              category={"City"}
              options={allCities}
              selectedOption={selectedCity}
              setSelectedOption={setSelectedCity}
            />
            <Dropdown
              category={"School"}
              options={allSchools}
              selectedOption={selectedSchool}
              setSelectedOption={setSelectedSchool}
            />
          </div>
          <div className={styles.tableContainer}>
            {filteredStudents.length > 0 ? (
              <table className={styles.programsTable}>
                <thead>
                  <tr>
                    <th className={styles.th}>Student Name</th>
                    <th className={styles.th}>State</th>
                    <th className={styles.th}>District</th>
                    <th className={styles.th}>City</th>
                    <th className={styles.th}>School Name</th>
                    <th className={styles.th}>Update</th>
                    <th className={styles.th}>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredStudents.map((student, index) => {
                    // const key = Object.keys(program);
                    // const studentName = key[0];
                    // const studentProgram = program[studentName];
                    return (
                      <tr key={index}>
                        <td className={styles.td}>
                          {student.first_name} {student.last_name}
                        </td>
                        <td className={styles.td}>{student.add_state}</td>
                        <td className={styles.td}>{student.add_dist}</td>
                        <td className={styles.td}>{student.add_city}</td>
                        <td className={styles.td}>{student.schoolName}</td>
                        <td className={styles.td}>
                          <span
                            className={`${styles.details} ${styles.updateButton}`}
                            onClick={() => handleOpenForm(student)}
                          >
                            Update
                          </span>
                        </td>
                        <td className={styles.td}>
                          <span
                            className={`${styles.details} ${styles.deleteButton}`}
                            onClick={() => handelDeleteStudent(student)}
                          >
                            Delete
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <p className={styles.p}>
                Sorry!! No Record Found for this location.
              </p>
            )}
          </div>
        </div>
      ) : (
        <>
          <button
            className={styles.goBackButton}
            onClick={() => dispatch(setStudentProfileOpened(false))}
          >
            &laquo; Go Back
          </button>
          <StudentProfile />
        </>
      )}
    </>
  );
};
export default StudentProfiles;
