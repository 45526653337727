import React, { useState } from "react";
import styles from "./AdminPage.module.css";
import SchoolProfiles from "./subpages/SchoolProfiles/SchoolProfiles";
import StudentProfiles from "./subpages/StudentProfiles/StudentProfiles";
import StudentsRegisteredInEpic from "./subpages/studentsRegisteredInEpic/StudentsRegisteredInEpic";
import EpicPrograms from "./subpages/EpicPrograms/EpicPrograms";

const AdminPage = () => {
  const [selectedTab, setSelectedTab] = useState("schoolProfiles");

  return (
    <div className={styles.studentPage}>
      <div className={styles.left}>
        <span
          className={`${styles.leftOption} ${
            selectedTab === "schoolProfiles" ? styles.activeOption : ""
          }`}
          onClick={() => setSelectedTab("schoolProfiles")}
        >
          School Profiles
        </span>
        <span
          className={`${styles.leftOption} ${
            selectedTab === "studentProfiles" ? styles.activeOption : ""
          }`}
          onClick={() => setSelectedTab("studentProfiles")}
        >
          Student Profiles
        </span>
        <span
          className={`${styles.leftOption} ${
            selectedTab === "studentsRegisteredInEpic"
              ? styles.activeOption
              : ""
          }`}
          onClick={() => setSelectedTab("studentsRegisteredInEpic")}
        >
          Students Registered in Epic Programs
        </span>
        <span
          className={`${styles.leftOption} ${
            selectedTab === "EpicPrograms" ? styles.activeOption : ""
          }`}
          onClick={() => {
            setSelectedTab("EpicPrograms");
          }}
        >
          EPIC Programs
        </span>
        <span
          className={`${styles.leftOption} ${
            selectedTab === "download" ? styles.activeOption : ""
          }`}
          onClick={() => {
            setSelectedTab("download");
          }}
        >
          Download Certificate
        </span>
      </div>
      <div className={styles.right}>
        {selectedTab === "schoolProfiles" && <SchoolProfiles />}
        {selectedTab === "studentProfiles" && <StudentProfiles />}
        {selectedTab === "studentsRegisteredInEpic" && (
          <StudentsRegisteredInEpic />
        )}
        {selectedTab === "EpicPrograms" && <EpicPrograms />}
        {/* {selectedTab === "download" && <DownloadCertificate />} */}
      </div>
    </div>
  );
};

export default AdminPage;
