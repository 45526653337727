import React from 'react'
import Typography from "@mui/material/Typography";


const TillClass8 = () => {
    return (
        <div style={{ marginBottom: '1rem' }}>
            <div style={{ width: '100%', height: 'auto', borderRadius: '5px' }}>
                <Typography variant="h5" gutterBottom style={{ fontSize: '2rem', color: 'teal' }}>
                    B. MIDDLE CLASS (6th - 8th) - Early Learners: Focused Learning
                </Typography>
                <Typography variant="h6" style={{ listStyleType: 'disc', fontSize: '1.8rem', paddingLeft: '20px' }}>
                    <span style={{ color: 'darkorange' }}>Introduction to Computer Basics & Advance. Different Computer Parts</span>
                </Typography>
                <ul style={{ listStyleType: 'disc', paddingLeft: '40px' }}>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Definition and basic understanding.</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Importance of computers in daily life.</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Basic Parts of a Computer: & function of each part.</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Different types of computers.</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; What is Hardware & Computer Software? Understanding the difference.</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Detailed understanding of Hardware & Software.</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Introduction to Input & Output devices: Definition and basic understanding & Different types of Input & Output devices.</li>
                </ul>
                <Typography variant="h6" style={{ listStyleType: 'disc', fontSize: '1.8rem', paddingLeft: '20px' }}>
                    <span style={{ color: 'darkorange' }}>Introduction to Software & Hardware, Difference Between S/w & H/w</span>
                </Typography>
                <ul style={{ listStyleType: 'disc', paddingLeft: '40px' }}>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Understanding what hardware is</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Examples of common hardware devices</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Types of Hardware</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Input Devices - Keyboard, Mouse, Scanner, Microphone</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Output Devices - Monitor, Printer, Speakers, Projector</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Storage Devices - Hard Disk Drives (HDD), Solid State Drives (SSD), USB Flash Drives, Memory Cards</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Processing Devices - Central Processing Unit (CPU), Graphics Processing Unit (GPU)</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Understanding Hardware Components - Motherboard, Main circuit board, Connecting all hardware components</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; CPU (Central Processing Unit) - Brain of the computer, Basic functions (processing instructions)</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; RAM (Random Access Memory) - Temporary storage for quick access, Importance for system performance</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Storage Devices - Primary storage vs. secondary storage, How data is saved and retrieved</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Understanding what software is</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Examples of common software applications</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Types of Software: System Software (OS), Application Software</li>
                </ul>
                <Typography variant="h6" style={{ listStyleType: 'disc', fontSize: '1.8rem', paddingLeft: '20px' }}>
                    <span style={{ color: 'darkorange' }}>Operating Systems: Introduction to Different OS</span>
                </Typography>
                <ul style={{ listStyleType: 'disc', paddingLeft: '40px' }}>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; What is an Operating System and what is its role in a computer?</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Different types of Operating Systems available today: Windows, Linux, MAC, Android, etc.</li>
                </ul>
                <Typography variant="h6" style={{ listStyleType: 'disc', fontSize: '1.8rem', paddingLeft: '20px' }}>
                    <span style={{ color: 'darkorange' }}>Detailed on Windows Operating System</span>
                </Typography>
                <ul style={{ listStyleType: 'disc', paddingLeft: '40px' }}>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; What is Windows OS and Available versions?</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Account, File & Folder management, Internet browsing, etc.</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Advanced Features like security settings, System monitoring, etc.</li>
                </ul>
                <Typography variant="h6" style={{ listStyleType: 'disc', fontSize: '1.8rem', paddingLeft: '20px' }}>
                    <span style={{ color: 'darkorange' }}>Word Processing & Spreadsheet Formatting (MS Word & Excel)</span>
                </Typography>
                <ul style={{ listStyleType: 'disc', paddingLeft: '40px' }}>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; MS Word</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; MS Excel for Spreadsheet formatting</li>
                </ul>
                <Typography variant="h6" style={{ listStyleType: 'disc', fontSize: '1.8rem', paddingLeft: '20px' }}>
                    <span style={{ color: 'darkorange' }}>Making Different Types of Presentations (MS PowerPoint)</span>
                </Typography>
                <ul style={{ listStyleType: 'disc', paddingLeft: '40px' }}>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; What are presentations?</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Why we need presentations & their importance.</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Different types of presentation formats.</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Making presentations with MS PowerPoint.</li>
                </ul>
                <Typography variant="h6" style={{ listStyleType: 'disc', fontSize: '1.8rem', paddingLeft: '20px' }}>
                    <span style={{ color: 'darkorange' }}>Introduction to the Internet & Its Uses</span>
                </Typography>
                <ul style={{ listStyleType: 'disc', paddingLeft: '40px' }}>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; What is the Internet?</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Brief history of the Internet</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Key milestones in the development of the Internet</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Browsing the Internet</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; What is a web browser? Popular web browsers (e.g., Chrome, Firefox, Edge, Safari)</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Basic browser functions: address bar, tabs, navigation buttons</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Using a Web Browser: What is URL and Web Pages?</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Entering URLs and navigating web pages</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Using search engines effectively</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Bookmarking and history functions</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Uses of the Internet: Social Networking, Online Shopping, Education, Research, Gaming, Banking & Finance</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Internet safety & ethics</li>
                </ul>
                <Typography variant="h6" style={{ listStyleType: 'disc', fontSize: '1.8rem', paddingLeft: '20px' }}>
                    <span style={{ color: 'darkorange' }}>Social Media Uses & Ethics</span>
                </Typography>
                <ul style={{ listStyleType: 'disc', paddingLeft: '40px' }}>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; What is social media?</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Popular social media platforms (e.g., Facebook, Twitter, Instagram, TikTok, Snapchat)</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Types of Social Media: Social networking sites, Micro-blogging platforms, Multimedia sharing platforms, Forums, and discussion sites</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Uses of Social Media</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Social Media Ethics</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Practical Activities and Projects</li>
                </ul>
                <Typography variant="h6" style={{ listStyleType: 'disc', fontSize: '1.8rem', paddingLeft: '20px' }}>
                    <span style={{ color: 'darkorange' }}>E-mail: How to Create and Its Uses</span>
                </Typography>
                <ul style={{ listStyleType: 'disc', paddingLeft: '40px' }}>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Introduction and facts about Email</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Understanding E-mail Components: E-mail address format (e.g., username@domain.com)</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Common terms: inbox, outbox, sent items, drafts, spam/junk</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Basic e-mail structure: subject line, body, attachments</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Step-by-Step Account Creation</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Sending and Receiving E-mails</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; E-mail Etiquette</li>
                </ul>
                <Typography variant="h6" style={{ listStyleType: 'disc', fontSize: '1.8rem', paddingLeft: '20px' }}>
                    <span style={{ color: 'darkorange' }}>E-Commerce, Cyber Security & Ethics</span>
                </Typography>
                <ul style={{ listStyleType: 'disc', paddingLeft: '40px' }}>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; E-Commerce: What is E-Commerce, its importance & benefits</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Type of E-commerce platforms</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; How to use E-Commerce platforms</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Emerging Trends in E-Commerce</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Practical activities</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Cyber Security & Ethics: What is cyber security?</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Importance of protecting information online</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Common cyber threats (e.g., viruses, malware, phishing, hacking)</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Understanding the internet and how data travels</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Safe Internet Practices: Recognizing secure websites (HTTPS, padlock symbol), Avoiding suspicious links and downloads</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Responsible Online Behaviour</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Practical Activities</li>
                </ul>
                <Typography variant="h6" style={{ listStyleType: 'disc', fontSize: '1.8rem', paddingLeft: '20px' }}>
                    <span style={{ color: 'darkorange' }}>Introduction to Computer Programming Languages</span>
                </Typography>
                <ul style={{ listStyleType: 'disc', paddingLeft: '40px' }}>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Understanding what a computer programming language is</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Importance of programming in the modern world</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Types of Programming Languages</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Different programming languages available</li>
                </ul>
                <Typography variant="h6" style={{ listStyleType: 'disc', fontSize: '1.8rem', paddingLeft: '20px' }}>
                    <span style={{ color: 'darkorange' }}>Basic Programming in Python</span>
                </Typography>
                <ul style={{ listStyleType: 'disc', paddingLeft: '40px' }}>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Learning Python programming</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Implementing basic programming concepts</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Writing a simple Python program and running it</li>
                </ul>
                <Typography variant="h6" style={{ listStyleType: 'disc', fontSize: '1.8rem', paddingLeft: '20px' }}>
                    <span style={{ color: 'darkorange' }}>Introduction to AI, Different Types of AI, and Uses</span>
                </Typography>
                <ul style={{ listStyleType: 'disc', paddingLeft: '40px' }}>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; What is Artificial Intelligence (AI)?</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Understanding AI vs. human intelligence</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Different types of AI - Narrow, General, AI Super Intelligence</li>
                    <li style={{ fontSize: '1.4rem' }}>&#x2022; Learn the use of AI tools, practical work</li>
                </ul>
            </div>
        </div>
    )
}

export default TillClass8
