import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  student: {},
  school: {},
  program: {},
  studentProfileOpened: false,
  schoolProfileOpened: false,
  programOpened: false,
};

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    updateStudent: (state, action) => {
      state.student = action.payload;
    },
    updateSchool: (state, action) => {
      state.school = action.payload;
    },
    updateProgramEdit: (state, action) => {
      state.program = action.payload;
    },
    setStudentProfileOpened: (state, action) => {
      state.studentProfileOpened = action.payload;
    },
    setSchoolProfileOpened: (state, action) => {
      state.schoolProfileOpened = action.payload;
    },
    setProgramOpened: (state, action) => {
      state.programOpened = action.payload;
    },
  },
});

export const {
  updateStudent,
  updateSchool,
  updateProgramEdit,
  setStudentProfileOpened,
  setSchoolProfileOpened,
  setProgramOpened,
} = adminSlice.actions;
export default adminSlice.reducer;
