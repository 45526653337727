import React from "react";
import "./Objective.css";
const Objective = () => {
  return (
    <>
      {/* <div className="itstaff-top" id="#Objective"> */}
        {/* <TopSection
          heading={"IT Staffing Services"}
          text={"Solutions to all your IT Staffing challanges"}
          to={"/contact"}
        /> */}
      {/* </div> */}
        <h1 className="section-title" >Objectives of <span>EPIC Programs</span></h1>
        <br />
      <main className="main-container">
        {/* Start IT Talent Section  */}
        {/* <TextComponent
          para1={"EMPOWERING BUSINESS GROWTH WITH ON-DEMAND TECH TALENT"}
          para2={
            "Business moves fast. Digital transformation makes you even faster.Is your IT department keeping up with demand?"
          }
          para3={
            "At Icoess Solutions, we accelerate growth for our customers by equipping them with the professional IT workforce they need right now. We offer a customizable, scalable approach to IT staffing, supporting where you are today and where you need to be in the future."
          }
          para4={
            "Get resumes in hand within 24-48 hours and start running a more flexible and productive organization."
          }
        /> */}
        {/* End IT Talent Section  */}

        {/* Start Technology we use Section  */}
        <section className=" section center">
          <div className="it">
            <div className="card2-item hover-objective article">
              <div className="card2-info">
                <h1>Empower Students with Digital Skills</h1>
                {/* <h4 className="heading4">INSTANT ACCESS, WITH ROOM TO GROW</h4> */}
                <p>
                We aim to equip young learners with essential computer skills that are crucial in today’s digital world. Our programs cover everything from basic computer literacy to advanced coding and programming.

                </p>
                {/* <NavLink className="cta" to={"/contact"}>
                  SCHEDULE A CALL
                </NavLink> */}
              </div>
              <div className="card2-img">
                <img src="assets/empower.jpg" alt="img" />{" "}
              </div>
            </div>
            <div className="card2-item  hover-objective article">
              <div className="card2-info">
                <h1>Promote Equal Opportunities</h1>
                {/* <h4 className="heading4">FULL-TIME TECH TALENT</h4> */}
                <p>
                We are committed to reaching underserved communities, ensuring that children from rural and economically disadvantaged backgrounds have the same opportunities to learn and excel in technology as their urban counterparts.
                </p>
                {/* <NavLink className="cta" to={"/contact"}>
                  GROW YOUR IT TALENT
                </NavLink> */}
              </div>
              <div className="card2-img">
                <img src="assets/eqality.jpg" alt="img" />{" "}
              </div>
            </div>
            <div className="card2-item  hover-objective article">
              <div className="card2-info">
                <h1>Faster Creativity and Innovation</h1>
                {/* <h4 className="heading4">SHORT-TERM HELP, LONG-TERM IMPACT</h4> */}

                <p>
                By introducing students to coding, robotics, and other STEM activities, we inspire creativity and encourage them to think critically and solve problems innovatively.
                </p>
                {/* <NavLink className="cta" to={"/contact"}>
                  GET CONTRACTED IT STAFF
                </NavLink> */}
              </div>
              <div className="card2-img">
                <img src="assets/faster.jpg" alt="img" />{" "}
              </div>
            </div>
            <div className="card2-item  hover-objective article">
              <div className="card2-info">
                <h1>Build a Tech-Savvy Generation</h1>
                {/* <h4 className="heading4">SHORT-TERM HELP, LONG-TERM IMPACT</h4> */}

                <p>
                Our vision is to nurture a generation of tech-savvy individuals who are well-prepared to contribute to India’s growth in the global digital economy. We believe early exposure to technology education will open doors to future career opportunities and personal growth.
                </p>
                {/* <NavLink className="cta" to={"/contact"}>
                  GET CONTRACTED IT STAFF
                </NavLink> */}
              </div>
              <div className="card2-img">
                <img src="assets/tech.webp" alt="img" />{" "}
              </div>
            </div>
            <div className="card2-item  hover-objective article">
              <div className="card2-info">
                <h1>Cultivate a Love for Learning</h1>
                {/* <h4 className="heading4">SHORT-TERM HELP, LONG-TERM IMPACT</h4> */}

                <p>
                We focus on making learning enjoyable and engaging through interactive lessons and hands-on activities. Our goal is to instill a lifelong love for learning and exploration in our students.
                </p>
                {/* <NavLink className="cta" to={"/contact"}>
                  GET CONTRACTED IT STAFF
                </NavLink> */}
              </div>
              <div className="card2-img">
                <img src="assets/love.jpg" alt="img" />{" "}
              </div>
            </div>
          </div>
        </section>
        {/* End Technology we use Section  */}
      </main>
      {/*  Start Contact Section */}
      <div className="mobile-contact-section" id="">
        {/* <ContactSection
          text={
            "Talk to us for your business needs, all staffing requirements and anything on technology. We would love to here from you & share our expertise with you"
          }
        /> */}
      </div>
      {/*  End Contact Section */}
    </>
  );
};

export default Objective;
