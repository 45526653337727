import React from "react";
import styles from "./Modal.module.css";
import { RxCross2 } from "react-icons/rx";

const Modal = ({ show, onClose, children }) => {
  if (!show) {
    return null;
  }

  // Clone the children and pass the onClose prop to each child
  const childrenWithOnClose = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { onClose });
    }
    return child;
  });

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <button className={styles.closeButton} onClick={onClose}>
          <RxCross2 className={styles.cross} />
        </button>
        {childrenWithOnClose}
      </div>
    </div>
  );
};

export default Modal;