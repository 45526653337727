import React, { useState } from "react";
import styles from "./SchoolPage.module.css";
import SchoolProfile from "./subpages/profile/SchoolProfile";
import StudentSignUp from "./subpages/studentSignUp/StudentSignUp";
import RegisterToProgram from "./subpages/registerToProgram/RegisterToProgram";
import RegisteredEpicPrograms from "./subpages/RegisteredPrograms/RegisteredEpicPrograms";

const StudentPage = () => {
  const [selectedTab, setSelectedTab] = useState("profile");

  return (
    <div className={styles.studentPage}>
      <div className={styles.left}>
        <span
          className={`${styles.leftOption} ${
            selectedTab === "profile" ? styles.activeOption : ""
          }`}
          onClick={() => setSelectedTab("profile")}
        >
          School Profile
        </span>
        <span
          className={`${styles.leftOption} ${
            selectedTab === "signUpStudent" ? styles.activeOption : ""
          }`}
          onClick={() => {
            setSelectedTab("signUpStudent");
          }}
        >
          Sign Up Student
        </span>
        <span
          className={`${styles.leftOption} ${
            selectedTab === "register" ? styles.activeOption : ""
          }`}
          onClick={() => {
            setSelectedTab("register");
          }}
        >
          Register Student in EPIC Program
        </span>
        <span
          className={`${styles.leftOption} ${
            selectedTab === "registered" ? styles.activeOption : ""
          }`}
          onClick={() => {
            setSelectedTab("registered");
          }}
        >
          View Students Registered EPIC Program
        </span>
        <span
          className={`${styles.leftOption} ${
            selectedTab === "download" ? styles.activeOption : ""
          }`}
          onClick={() => {
            setSelectedTab("download");
          }}
        >
          Download Certificate
        </span>
      </div>
      <div className={styles.right}>
        {selectedTab === "profile" && <SchoolProfile />}
        {selectedTab === "signUpStudent" && <StudentSignUp />}
        {selectedTab === "register" && <RegisterToProgram />}
        {selectedTab === "registered" && <RegisteredEpicPrograms />}
      </div>
    </div>
  );
};

export default StudentPage;
