import React, { useEffect, useState } from "react";
import styles from "./RegisterToProgram.module.css";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  toggleSchoolProgramModal,
  updateStudent,
} from "../../../../../store/user/schoolProgramSlice";

const RegisterToProgram = () => {
  const initialStudent = {
    _id: "",
    first_name: "",
    last_name: "",
  };

  const [student, setStudent] = useState(initialStudent);
  const [classValue, setClassValue] = useState("");
  const [allStudents, setAllStudents] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [isRegisterEnabled, setRegisterEnabled] = useState(false);
  const { currentUser } = useSelector((state) => state.user);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const dispatch = useDispatch();

  const handleStudentChange = (e) => {
    const currStudent = filteredStudents.find(
      (student) => student._id === e.target.value
    );
    if (!currStudent) {
      setStudent(initialStudent);
    } else {
      setStudent(currStudent);
    }
    setRegisterEnabled(!!e.target.value); // Enable register if student is selected
  };

  const handleClassChange = (e) => {
    setClassValue(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Student:==>", student);
    dispatch(updateStudent(student));
    dispatch(toggleSchoolProgramModal());
    // alert("Student registered!");
  };

  useEffect(() => {
    if (!currentUser) return;
    const fetchStudentData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/schoolStudents/${currentUser.user._id}`
        );
        const data = response.data;
        setAllStudents(data); // Set the student data
        setLoading(false); // Data is loaded
        console.log("All Students data:", response.data);
      } catch (err) {
        console.error("Error fetching student data:", err);
        setError("Could not fetch student data");
        setLoading(false);
      }
    };

    fetchStudentData();
  }, []);

  useEffect(() => {
    if (!classValue) return;
    const currFilteredStudents = allStudents.filter(
      (student) => student.std_grade === classValue
    );
    setFilteredStudents(currFilteredStudents);
  }, [classValue]);

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <p className={styles.loading}>Loading...</p>;
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      <h2 className={`${styles.title} ${styles.h2}`}>
        Register your student in EPIC program
      </h2>

      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.grid}>
          <div>
            <label className={styles.label} htmlFor="student">
              Select Grade <span className={styles.required}>*</span>
            </label>
            <select
              id="class"
              className={styles.input}
              value={classValue}
              onChange={handleClassChange}
            >
              <option value="">Select</option>
              <option value="1-5">1-5</option>
              <option value="6-8">6-8</option>
              <option value="9-10">9-10</option>
              <option value="11-12">11-12</option>
            </select>
          </div>
          <div>
            <label className={styles.label} htmlFor="student">
              Select Student <span className={styles.required}>*</span>
            </label>
            <select
              id="student"
              className={styles.input}
              value={student._id}
              onChange={handleStudentChange}
            >
              <option value="">Select</option>
              {filteredStudents.map((student, index) => {
                return (
                  <option key={index} value={student._id}>
                    {student.first_name + " " + student.last_name}
                  </option>
                );
              })}
            </select>
          </div>
          <div>
            <label className={styles.label} htmlFor="stdName">
              Std Name
            </label>
            <input
              disabled
              type="text"
              id="stdName"
              value={
                student.first_name
                  ? student.first_name + " " + student.last_name
                  : ""
              }
              className={styles.input}
            />
          </div>
          <div>
            <label className={styles.label} htmlFor="fatherName">
              Father Name
            </label>
            <input
              disabled
              type="text"
              id="fatherName"
              className={styles.input}
              value={student.father_name ? student.father_name : ""}
            />
          </div>
          <div>
            <label className={styles.label} htmlFor="dob">
              DOB
            </label>
            <input
              disabled
              type="date"
              id="dob"
              value={
                student.date_of_birth ? student.date_of_birth.split("T")[0] : ""
              }
              className={styles.input}
            />
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <button
            type="submit"
            className={styles.registerButton}
            disabled={!isRegisterEnabled}
          >
            Register
          </button>
        </div>
      </form>
    </>
  );
};

export default RegisterToProgram;
