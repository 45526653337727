import React, { useEffect, useState } from "react";
import styles from "./ProgramRegistration.module.css";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { toggleSchoolProgramModal } from "../../../../../../store/user/schoolProgramSlice";

const ProgramRegistration = () => {
  // const [studentData, setStudentData] = useState(null); // To hold the student data
  const [programs, setPrograms] = useState([]);
  const [currentProgram, setCurrentProgram] = useState({});
  const [courseFees, setCourseFees] = useState(0);
  // const [tot_fee_paid, settot_fee_paid] = useState(0);
  const [loading, setLoading] = useState(true); // Loading state
  const [detailsOpened, setDetailsOpened] = useState(false);
  const [pending_amount, setPending_amount] = useState(0);
  const [error, setError] = useState(null); // Error state
  const { currentUser } = useSelector((state) => state.user);
  const { student } = useSelector((state) => state.schoolProgram);
  const dispatch = useDispatch();

  const generateUniqueId = () => {
    const specialChars = "abcdefg";
    const randomChar =
      specialChars[Math.floor(Math.random() * specialChars.length)];

    // Generate the initial unique ID
    let uniqueId = `${Date.now()}${Math.floor(Math.random() * 1000)}`;

    // Function to insert a character at a random position
    const insertCharAtRandomIndex = (str, char) => {
      const randomIndex = Math.floor(Math.random() * str.length);
      return str.slice(0, randomIndex) + char + str.slice(randomIndex);
    };

    // Insert a random special character at a random position within the ID
    for (let i = 0; i < 3; i++) {
      // Insert 3 random special characters
      const randomSpecialChar =
        specialChars[Math.floor(Math.random() * specialChars.length)];
      uniqueId = insertCharAtRandomIndex(uniqueId, randomSpecialChar);
    }

    return uniqueId;
  };

  const getTodayDate = () => {
    const today = new Date();
    return today.toISOString().split("T")[0]; // Get YYYY-MM-DD format
  };

  const [formData, setFormData] = useState({
    prog_regis_id: generateUniqueId(),
    student_id: student && student._id,
    prog_id: currentProgram._id,
    registration_dt: getTodayDate(),
    studentName: student ? student.first_name + " " + student.last_name : "",
    prog_status: "Pending",
    prog_fee: courseFees,
    tot_fee_paid: 0,
    payment_status: "Pending",
    pending_amount: pending_amount,
    paymentTxnId: "",
    remarks: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    window.scrollTo(0, 0);
    console.log(formData, "formData");
    try {
      // Make the request to your backend API
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/studentProgramRegister/${student._id}`,
        formData
      ); // Replace with your backend URL

      console.log("Response:", response);

      if (response.status === 201) {
        setLoading(false);
        dispatch(toggleSchoolProgramModal());

        alert("Student registered successfully");
        // sleep();
      }
    } catch (error) {
      alert("Already registered to this program...");

      setError(
        error.response ? error.response.data.error : "Registration failed"
      );
      dispatch(toggleSchoolProgramModal());
    } finally {
      setFormData((prev) => ({
        ...prev,
        prog_regis_id: generateUniqueId(),
        paymentTxnId: "",
        remarks: "",
        tot_fee_paid: 0,
      }));
      setLoading(false);
    }
  };

  useEffect(() => {
    // Function to fetch student data from the backend
    console.log("current user", currentUser.user._id);
    const fetchStudentData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/students/${student._id}`
        );
        const programsResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/getAllPrograms`
        );
        // setStudentData(response.data[0]); // Set the student data
        setPrograms(programsResponse.data);
        console.log(programsResponse.data);
        setLoading(false); // Data is loaded
        console.log("Student data:", response.data[0]);
      } catch (err) {
        console.error("Error fetching student data:", err);
        setError("Could not fetch student data");
        setLoading(false);
      }
    };

    fetchStudentData(); // Call the function when component mounts
  }, [currentUser]); // Re-run if studentId changes

  useEffect(() => {
    if (!formData.program) return;
    const program = programs.find(
      (program) => program.prog_name === formData.program
    );

    if (program) {
      setCurrentProgram(program);
      console.log(program);
      const fees = program.prog_fee !== undefined ? program.prog_fee : 0;
      setCourseFees(fees);
      setPending_amount(fees - formData.tot_fee_paid);
      setFormData((prev) => ({
        ...prev,
        prog_fee: fees,
        pending_amount: fees - formData.tot_fee_paid,
        student_id: student._id,
        prog_id: currentProgram._id,
      }));
    }
    // console.log("Total Amount: ", formData.prog_fee);
    // console.log("Remaining Amount: ", calculatePendingFee());
  }, [formData.program, formData.tot_fee_paid, programs, student]);

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <p className={`${styles.loading} ${styles.p}`}>Loading...</p>;
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <form className={styles.registrationForm} onSubmit={handleSubmit}>
      <h2 className={styles.h2}>Student Program Registration Form</h2>

      {/* Program Registration ID */}
      <div className={styles.grid}>
        <label className={styles.label}>
          <span>Program Registration Id</span>

          {/* <span className={styles.values}>{" " + formData.prog_regis_id}</span> */}
          <input
            disabled
            className={`${styles.unchangableData} ${styles.input} `}
            type="text"
            value={formData.prog_regis_id}
          />
        </label>

        {/* Student Name */}

        <label className={styles.label}>
          <span>Student Name</span>

          {/* <span className={styles.values}>
            {" " + studentData.first_name + " " + studentData.last_name}
            
          </span> */}
          <input
            disabled
            className={`${styles.unchangableData} ${styles.input} `}
            type="text"
            value={student.first_name + " " + student.last_name}
          />
        </label>

        {/* Select Program */}
        <label className={styles.label}>
          Select Program <span className={styles.required}>*</span>
          <select
            name="program"
            value={formData.program}
            onChange={handleInputChange}
            className={`${styles.selectOption} ${styles.select}`}
          >
            <option value="">Select a program</option>
            {/* <option value="COP">Computer Outreach Program (COP)</option>
                <option value="Junior Olympiads">Junior Olympiads</option> */}
            {programs.map((program) => {
              return (
                <option key={program._id} value={program.prog_name}>
                  {program.prog_name}
                </option>
              );
            })}
          </select>
        </label>
        <label className={styles.label}>
          <button
            type="button"
            onClick={() => setDetailsOpened(!detailsOpened)}
            className={`${styles.detailsButton} ${styles.button}`}
          >
            {detailsOpened ? "Hide details" : "Show details"}
          </button>
        </label>
      </div>

      <div className={styles.container}>
        {detailsOpened && programs.length > 0 ? (
          formData.program ? (
            <ul className={styles.programList}>
              {/* {programs.map((program) => ( */}
              <li key={currentProgram._id} className={styles.programItem}>
                <h2 className={styles.programTitle}>
                  {currentProgram.prog_name}
                </h2>
                <p className={`${styles.programDetail} ${styles.p}`}>
                  Type: {currentProgram.prog_type}
                </p>
                <p className={`${styles.programDetail} ${styles.p}`}>
                  Description: {currentProgram.prog_description}
                </p>
                <p className={`${styles.programDetail} ${styles.p}`}>
                  Duration: {currentProgram.prog_duration} days
                </p>
                <p className={`${styles.programDetail} ${styles.p}`}>
                  Level: {currentProgram.level}
                </p>
                <p
                  className={`${styles.programDetail} ${styles.fee} ${styles.p}`}
                >
                  Fee: {" " + currentProgram.prog_fee + " "} USD
                </p>
                <p
                  className={`${styles.programDetail} ${styles.dates} ${styles.p}`}
                >
                  Start Date:{" "}
                  {new Date(currentProgram.start_date).toLocaleDateString()}
                </p>
                <p
                  className={`${styles.programDetail} ${styles.dates} ${styles.p}`}
                >
                  End Date:{" "}
                  {new Date(currentProgram.end_date).toLocaleDateString()}
                </p>
              </li>
              {/* ))} */}
            </ul>
          ) : (
            <p className={`${styles.selectAProgram} ${styles.p}`}>
              Please select a program
            </p>
          )
        ) : (
          ""
        )}
      </div>

      {/* Program Details (Start Date, End Date, Status, Fee) */}
      <div className={styles.grid}>
        <label className={styles.label}>
          <span>Program Start Date</span>

          {/* <span className={styles.values}>
            {currentProgram.start_date &&
              " " +
                new Date(currentProgram.start_date).toLocaleDateString() +
                " "}
          </span> */}

          <input
            disabled
            type="text"
            className={`${styles.unchangableData} ${styles.input} `}
            value={
              currentProgram.start_date
                ? new Date(currentProgram.start_date).toLocaleDateString()
                : ""
            }
          />
        </label>

        <label className={styles.label}>
          <span>Program End Date</span>
          {/* <span className={styles.values}>
            {currentProgram.end_date &&
              " " +
                new Date(currentProgram.end_date).toLocaleDateString() +
                " "}
          </span> */}
          <input
            disabled
            type="text"
            className={`${styles.unchangableData} ${styles.input} `}
            value={
              currentProgram.end_date
                ? new Date(currentProgram.end_date).toLocaleDateString()
                : ""
            }
          />
        </label>

        <label className={styles.label}>
          Program Status
          <input
            disabled
            className={`${styles.unchangableData} ${styles.input} `}
            type="text"
            name="prog_status"
            value={"Pending"}
          />
        </label>

        <label className={styles.label}>
          <span>Program Fee</span>
          {/* <span className={styles.values}>
            {currentProgram.end_date && " " + currentProgram.prog_fee + " USD"}
          </span> */}

          <input
            disabled
            type="text"
            name="prog_fee"
            className={`${styles.unchangableData} ${styles.input} `}
            value={
              currentProgram.prog_fee ? "Rs." + currentProgram.prog_fee : ""
            }
            onChange={() => {
              setCourseFees(
                currentProgram.prog_fee !== undefined
                  ? currentProgram.prog_fee
                  : 0
              );
              handleInputChange();
            }}
          />
        </label>
      </div>

      {/* Payment Details */}
      <h3>Make Payment</h3>

      <div className={styles.grid}>
        <div>
          <label className={styles.label}>
            Paid Fee Amount <span className={styles.required}>*</span>
            <input
              required
              type="number"
              name="tot_fee_paid"
              value={formData.tot_fee_paid}
              onChange={handleInputChange}
              className={`${styles.feesLabel} ${styles.input} `}
            />
          </label>

          <label className={styles.label}>
            Pending Fee Amount
            <input
              disabled
              type="number"
              name="pending_amount"
              value={formData.pending_amount}
              className={`${styles.feesLabel} ${styles.input} `}
              onChange={handleInputChange}
              // readOnly
            />
          </label>

          <label className={styles.label}>
            Payment Txn ID <span className={styles.required}>*</span>
            <input
              required
              type="text"
              name="paymentTxnId"
              value={formData.paymentTxnId}
              onChange={handleInputChange}
              className={`${styles.feesLabel} ${styles.input} `}
            />
          </label>
        </div>
        <div className={styles.qrContainer}>
          <img src="/qr/qr.png" alt="qr code" className={styles.qrCode} />
        </div>
      </div>
      <label className={styles.label}>
        Note (if Any)
        <textarea
          name="remarks"
          value={formData.remarks}
          className={styles.textarea}
          onChange={handleInputChange}
        />
      </label>

      <div className={styles.buttons}>
        <button className={styles.button} type="submit">
          Register
        </button>
        <button
          className={styles.button}
          type="button"
          onClick={() => setFormData({})}
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

export default ProgramRegistration;
