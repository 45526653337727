import { useDispatch } from "react-redux";
import { signOut } from "../../store/user/userSlice"; // Import your sign-out action
import Style from "./Logout.module.css";
const Logout = () => {
  const dispatch = useDispatch();

  const logout = () => {
    // Clear token from local storage
    localStorage.removeItem("token");

    // Dispatch a sign-out action to reset the user state in Redux
    dispatch(signOut());

    // Redirect to the login page or home page
    alert("Logged out...");
  };

  return (
    <button className={`${Style.button} ${Style.logout}`} onClick={logout}>
      Logout
    </button>
  );
};

export default Logout;
