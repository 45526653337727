import React from 'react'
import "./Program.css"
const Program = () => {
    return (
        <>
            <div className="content">
                <h1 className="section-title">
                Program <span>Highlights   </span>
                </h1><br /><br />
            </div>
            <div className="Home-container">
                <section className="program center">
                    <div className="program-services">
                        <div className="program-about">
                            <div className="program-info">
                                <h1>Live Interactive Learning</h1>
                                {/* <h4 className="heading4">
                </h4> */}
                                <p>
                                    We make computer education engaging and fun through hands-on activities and interactive live class rooms.
                                </p>
                            </div>
                            <div className="program-img">
                                <img src="assets/live.jpg" alt="work" />{" "}
                            </div>
                        </div>
                    </div>
                </section>
                <section className=" program center">
                    <div className="program-services">
                        <div className="program-about">
                            <div className="program-info">
                                <h1>Curriculum Enhancement</h1>
                                {/* <h4 className="heading4">
                </h4> */}
                                <p>
                                    Designing age-appropriate, engaging curriculum that go beyond traditional rote learning, incorporating practical and interactive sessions to foster a deeper understanding of computer science concepts.
                                </p>
                            </div>
                            <div className="program-img">
                                <img src="assets/curricumlam.jpg" alt="work" />{" "}
                            </div>
                        </div>
                    </div>
                </section>
                <section className=" program center">
                    <div className="program-services">
                        <div className="program-about">
                            <div className="program-info">
                                <h1>Comprehensive Curriculum</h1>
                                {/* <h4 className="heading4">
                </h4> */}
                                <p>
                                    From basic computer literacy to advanced coding, AI and robotics, our programs cover a wide range of topics tailored to different age groups.
                                </p>
                            </div>
                            <div className="program-img">
                                <img src="assets/comprehensive.webp" alt="work" />{" "}
                            </div>
                        </div>
                    </div>
                </section>
                <section className=" program center">
                    <div className="program-services">
                        <div className="program-about">
                            <div className="program-info">
                                <h1>Inclusive Programs</h1>
                                {/* <h4 className="heading4">
                </h4> */}
                                <p>
                                    We also focus on reaching underprivileged and rural areas, ensuring that no child is left behind in the digital revolution.
                                </p>
                            </div>
                            <div className="program-img">
                                <img src="assets/rural.jpeg" alt="work" />{" "}
                            </div>
                        </div>
                    </div>
                </section>
                <section className=" program center">
                    <div className="program-services">
                        <div className="program-about">
                            <div className="program-info">
                                <h1>Expert Instructors</h1>
                                {/* <h4 className="heading4">
                </h4> */}
                                <p>
                                    Our team consists of experienced educators and tech professionals who are passionate about teaching and mentoring young minds.
                                </p>
                            </div>
                            <div className="program-img">
                                <img src="assets/mentor.png" alt="work" />{" "}
                            </div>
                        </div>
                    </div>
                </section>
                <section className=" program center">
                    <div className="program-services">
                        <div className="program-about">
                            <div className="program-info">
                                <h1>Extracurricular Activities</h1>
                                {/* <h4 className="heading4">
                </h4> */}
                                <p>
                                    Organizing coding clubs, hackathons, and competitions like the National Junior Coders Olympiad to stimulate interest and encourage practical application of skills learned.
                                </p>
                            </div>
                            <div className="program-img">
                                <img src="assets/extracurriculam.jpg" alt="work" />{" "}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default Program
