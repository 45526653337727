import React, { useEffect, useState } from "react";
import styles from "./SchoolProfile.module.css";
import axios from "axios";
import { useSelector } from "react-redux";

const SchoolProfile = () => {
  const [schoolData, setSchoolData] = useState(null);
  const { currentUser } = useSelector((state) => state.user);
  const { school } = useSelector((state) => state.admin);
  const [formData, setFormData] = useState({
    username: "",
    mobile_no: "",
    email: "",
    password: "",
    confirmPassword: "",
    school_name: "",
    school_type: "",
    school_board: "",
    add_school: "",
    add_city: "",
    add_dist: "",
    add_state: "",
    add_pin: "",
    school_registration_no: "",
    website: "",
    contact_person: "",
    contact_person_designation: "",
    contact_phone: "",
    contact_email: "",
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    setError("");
    setSuccessMessage("");
    window.scrollTo(0, 0);

    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/api/updateSchool/${schoolData._id}`,
        formData
      );

      console.log("Response:", response);
      console.log("Response status:", response.status);

      if (response.status === 200) {
        setSuccessMessage("School data updated successfully");
        alert("School data updated successfully");
      }
    } catch (error) {
      setError(
        error.response ? error.response.data.error : "Registration failed"
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchStudentData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/schools/${
            currentUser.user_type !== "school"
              ? school.user_id._id
              : currentUser.user_id
          }`
        );
        const data = response.data[0];
        setSchoolData(data);
        setFormData({
          username: data.user_id.username,
          mobile_no: data.user_id.mobile_no,
          email: data.user_id.email,
          password: "",
          confirmPassword: "",
          school_name: data.school_name,
          school_type: data.school_type,
          school_board: data.school_board,
          add_school: data.add_school,
          add_city: data.add_city,
          add_dist: data.add_dist,
          add_state: data.add_state,
          add_pin: data.add_pin,
          school_registration_no: data.school_registration_no,
          website: data.website,
          contact_person: data.contact_person,
          contact_person_designation: data.contact_person_designation,
          contact_phone: data.contact_phone,
          contact_email: data.contact_email,
        });
        setLoading(false);
        console.log("School data:", response.data[0]);
      } catch (err) {
        console.error("Error fetching student data:", err);
        setError("Could not fetch student data");
        setLoading(false);
      }
    };

    fetchStudentData();
  }, [currentUser]);

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <p className={styles.loading}>Loading...</p>;
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }
  return (
    <>
      <h2 className={`${styles.title} ${styles.h2}`}>Edit your profile</h2>

      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.grid}>
          <div>
            <label className={styles.label}>
              Username <span className={styles.required}>*</span>
            </label>
            <input
              type="text"
              name="username"
              value={formData.username}
              onChange={handleChange}
              required
              className={styles.input}
            />
          </div>
          <div>
            <label className={styles.label}>
              School Contact Number <span className={styles.required}>*</span>
            </label>
            <input
              type="tel"
              name="mobile_no"
              value={formData.mobile_no}
              onChange={handleChange}
              required
              className={styles.input}
            />
          </div>
          <div>
            <label className={styles.label}>
              School Email Address <span className={styles.required}>*</span>
            </label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              className={styles.input}
            />
          </div>
          <div>
            <label className={styles.label}>
              Password <span className={styles.required}>*</span>
            </label>
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
              className={styles.input}
            />
          </div>
          <div>
            <label className={styles.label}>
              Confirm Password <span className={styles.required}>*</span>
            </label>
            <input
              type="password"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
              required
              className={styles.input}
            />
          </div>
          {/* <div className={styles.checkboxContainer}>
            <label className={styles.label}>
              Two-Factor Authentication{" "}
              <span className={styles.required}>*</span>
            </label>
            <input
              className={styles.input}
              type="checkbox"
              name="twoFactorAuth"
              onChange={() =>
                setFormData({
                  ...formData,
                  twoFactorAuth: !formData.twoFactorAuth,
                })
              }
            />
          </div> */}
          <div>
            <label className={styles.label}>
              School Name <span className={styles.required}>*</span>
            </label>
            <input
              className={styles.input}
              type="text"
              name="school_name"
              value={formData.school_name}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label className={styles.label}>
              School Board <span className={styles.required}>*</span>
            </label>
            <input
              className={styles.input}
              type="text"
              name="school_board"
              value={formData.school_board}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label className={styles.label}>
              School Type <span className={styles.required}>*</span>
            </label>
            <select
              name="school_type"
              value={formData.school_type}
              onChange={handleChange}
              required
              className={styles.select}
            >
              <option value="">Select</option>
              <option value="Primary">Primary</option>
              <option value="Secondary">Secondary</option>
              <option value="Higher Secondary">Higher Secondary</option>
            </select>
          </div>
          <div>
            <label className={styles.label}>
              School Address <span className={styles.required}>*</span>
            </label>
            <input
              type="text"
              name="add_school"
              value={formData.add_school}
              onChange={handleChange}
              required
              className={styles.input}
            />
          </div>
          <div>
            <label className={styles.label}>
              City <span className={styles.required}>*</span>
            </label>
            <input
              type="text"
              name="add_city"
              value={formData.add_city}
              onChange={handleChange}
              required
              className={styles.input}
            />
          </div>
          <div>
            <label className={styles.label}>
              District <span className={styles.required}>*</span>
            </label>
            <input
              className={styles.input}
              type="text"
              name="add_dist"
              value={formData.add_dist}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label className={styles.label}>
              State <span className={styles.required}>*</span>
            </label>
            <input
              className={styles.input}
              type="text"
              name="add_state"
              value={formData.add_state}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label className={styles.label}>
              Pin Code <span className={styles.required}>*</span>
            </label>
            <input
              className={styles.input}
              type="text"
              name="add_pin"
              value={formData.add_pin}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label className={styles.label}>
              School Registration Number{" "}
              <span className={styles.required}>*</span>
            </label>
            <input
              className={styles.input}
              type="text"
              name="school_registration_no"
              value={formData.school_registration_no}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label className={styles.label}>School Website</label>
            <input
              type="url"
              name="website"
              value={formData.website}
              onChange={handleChange}
              className={styles.input}
            />
          </div>
          <div>
            <label className={styles.label}>
              Principal/Administrator Name{" "}
              <span className={styles.required}>*</span>
            </label>
            <input
              type="text"
              name="contact_person"
              value={formData.contact_person}
              onChange={handleChange}
              required
              className={styles.input}
            />
          </div>
          <div>
            <label className={styles.label}>
              Designation <span className={styles.required}>*</span>
            </label>
            <select
              name="contact_person_designation"
              value={formData.contact_person_designation}
              onChange={handleChange}
              required
              className={styles.select}
            >
              <option value="">Select</option>
              <option value="Principal">Principal</option>
              <option value="Director">Director</option>
              <option value="Coordinator">Coordinator</option>
            </select>
          </div>
          <div>
            <label className={styles.label}>
              Contact Number <span className={styles.required}>*</span>
            </label>
            <input
              type="tel"
              name="contact_phone"
              value={formData.contact_phone}
              onChange={handleChange}
              required
              className={styles.input}
            />
          </div>
          <div>
            <label className={styles.label}>
              Email Address <span className={styles.required}>*</span>
            </label>
            <input
              type="email"
              name="contact_email"
              value={formData.contact_email}
              onChange={handleChange}
              required
              className={styles.input}
            />
          </div>
          <div>
            <label className={styles.label}>
              Upload School Registration Certificate (Optional)
            </label>
            <input
              className={styles.input}
              type="file"
              name="schoolCertificate"
              accept=".pdf,.jpg,.png"
            />
          </div>
        </div>
        <div className={styles.submitBtnBox}>
          <button type="submit" className={styles.submitBtn}>
            {loading ? "Updating..." : "Update"}
          </button>
        </div>

        <div className={styles.formGroup}>
          {error && (
            <p className={styles.linkGroup} style={{ color: "red" }}>
              {error}
            </p>
          )}

          {successMessage && (
            <p className={styles.linkGroup} style={{ color: "green" }}>
              {successMessage}
            </p>
          )}
        </div>
      </form>
    </>
  );
};

export default SchoolProfile;
