import React, { useState } from "react";
import styles from "./AddProgramForm.module.css";
import axios from "axios";

const AddProgramForm = ({ fetchProgramsData }) => {
  const [formData, setFormData] = useState({
    prog_name: "",
    prog_type: "",
    prog_description: "",
    prog_duration: "",
    level: "",
    prog_fee: "",
    start_date: "",
    end_date: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSuccessMessage("");
    // window.scrollTo(0, 0);
    console.log("Form Submitted:", formData);
    // Handle form submission logic here
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/addProgram`,
        formData
      );
      console.log("Response:", response);

      if (response.status === 201) {
        alert("Program added successfully");
        setSuccessMessage("Program added successfully");
        setLoading(false);
        setFormData({
          prog_name: "",
          prog_type: "",
          prog_description: "",
          prog_duration: "",
          level: "",
          prog_fee: "",
          start_date: "",
          end_date: "",
        });
        fetchProgramsData();
      }
    } catch (error) {
      setError(error.response ? error.response.data.error : "Error occurred");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.grid}>
          <div>
            <label className={styles.label}>
              Program Name <span className={styles.required}>*</span>
            </label>
            <input
              type="text"
              name="prog_name"
              value={formData.prog_name}
              onChange={handleChange}
              required
              className={styles.input}
            />
          </div>
          <div>
            <label className={styles.label}>
              Program Type <span className={styles.required}>*</span>
            </label>
            <select
              name="prog_type"
              value={formData.prog_type}
              onChange={handleChange}
              required
              className={styles.select}
            >
              <option value="">Select</option>
              <option value="Training">Training</option>
              <option value="Assessment">Assessment</option>
              <option value="Olympiads">Olympiads</option>
            </select>
          </div>
          <div>
            <label className={styles.label}>
              Description <span className={styles.required}>*</span>
            </label>
            <textarea
              name="prog_description"
              value={formData.prog_description}
              onChange={handleChange}
              required
              className={styles.textarea}
            ></textarea>
          </div>
          <div>
            <label className={styles.label}>
              Duration (in weeks) <span className={styles.required}>*</span>
            </label>
            <input
              type="number"
              name="prog_duration"
              value={formData.prog_duration}
              onChange={handleChange}
              required
              className={styles.input}
            />
          </div>
          <div>
            <label className={styles.label}>
              Program Level <span className={styles.required}>*</span>
            </label>
            <select
              name="level"
              value={formData.level}
              onChange={handleChange}
              required
              className={styles.select}
            >
              <option value="">Select</option>
              <option value="Beginner">Beginner</option>
              <option value="Intermediate">Intermediate</option>
              <option value="Advanced">Advanced</option>
            </select>
          </div>
          <div>
            <label className={styles.label}>
              Program Fee <span className={styles.required}>*</span>
            </label>
            <input
              type="number"
              name="prog_fee"
              value={formData.prog_fee}
              onChange={handleChange}
              required
              className={styles.input}
            />
          </div>
          <div>
            <label className={styles.label}>
              Start Date <span className={styles.required}>*</span>
            </label>
            <input
              type="date"
              name="start_date"
              value={formData.start_date}
              onChange={handleChange}
              required
              className={styles.input}
            />
          </div>
          <div>
            <label className={styles.label}>
              End Date <span className={styles.required}>*</span>
            </label>
            <input
              type="date"
              name="end_date"
              value={formData.end_date}
              onChange={handleChange}
              required
              className={styles.input}
            />
          </div>
        </div>

        <div className={styles.submitBtnBox}>
          <button type="submit" className={styles.submitBtn}>
            {loading ? "Adding..." : "Add Program"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddProgramForm;
