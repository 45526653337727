import React, { useState } from "react";
import styles from "./AdminRegistrationForm.module.css";
import axios from "axios";

const AdminRegistrationForm = ({ onClose, setIsLoginOpen }) => {
  const [formData, setFormData] = useState({
    user_type: "admin",
    username: "",
    mobile_no: "",
    email: "",
    password: "",
    confirmPassword: "",
    twoFactorAuth: false,
    authorization_code: "",
    first_name: "",
    last_name: "",
    designation: "",
    organization_name: "",
    personal_Number: "",
    personal_email: "",
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = (name) => {
    setFormData({ ...formData, [name]: !formData[name] });
  };

  const sleep = () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        // alert("Registration successful...");
        setFormData({
          user_type: "admin",
          username: "",
          mobile_no: "",
          email: "",
          password: "",
          confirmPassword: "",
          twoFactorAuth: false,
          authorization_code: "",
          first_name: "",
          last_name: "",
          designation: "",
          organization_name: "",
          personal_Number: "",
          personal_email: "",
        });
        onClose();
        setIsLoginOpen(true);
      }, 500);
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    setError("");
    setSuccessMessage("");

    try {
      // Make the request to your backend API
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/registerAdmin`,
        formData
      ); // Replace with your backend URL

      console.log("Response:", response);

      if (response.status === 201) {
        setSuccessMessage("Admin registered successfully");
        sleep();
      }
    } catch (error) {
      setError(
        error.response ? error.response.data.error : "Registration failed"
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.formContainer}>
      <h2 className={styles.title}>Admin Sign-Up Form</h2>

      <form className={styles.form} onSubmit={handleSubmit}>
        {/* Login Credentials Section */}
        <div className={styles.section}>
          {/* <h3>Login Credentials</h3> */}

          <label className={styles.label}>
            Username <span className={styles.required}>*</span>
          </label>
          <input
            type="text"
            name="username"
            value={formData.username}
            onChange={handleChange}
            required
            className={styles.input}
          />

          <label className={styles.label}>
            Mobile Number <span className={styles.required}>*</span>
          </label>
          <input
            type="tel"
            name="mobile_no"
            value={formData.mobile_no}
            onChange={handleChange}
            pattern="\d{10}"
            title="Please enter a 10-digit mobile number"
            required
            className={styles.input}
          />

          <label className={styles.label}>
            Email Address (Official) <span className={styles.required}>*</span>
          </label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            className={styles.input}
          />

          <label className={styles.label}>
            Password <span className={styles.required}>*</span>
          </label>
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
            className={styles.input}
            pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
            title="Password must be at least 8 characters long, contain at least one number, one uppercase and one lowercase letter"
          />

          <label className={styles.label}>
            Confirm Password <span className={styles.required}>*</span>
          </label>
          <input
            type="password"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
            required
            className={styles.input}
          />

          <div className={styles.checkboxContainer}>
            <label className={styles.label}>
              Two-Factor Authentication{" "}
              <span className={styles.required}>*</span>
            </label>
            <input
              type="checkbox"
              name="twoFactorAuth"
              checked={formData.twoFactorAuth}
              onChange={() => handleCheckboxChange("twoFactorAuth")}
              className={styles.input}
            />
          </div>

          <label className={styles.label}>
            Authorization Code <span className={styles.required}>*</span>
          </label>
          <input
            type="text"
            name="authorization_code"
            value={formData.authorization_code}
            onChange={handleChange}
            required
            className={styles.input}
          />
        </div>

        {/* Personal Information Section */}
        <div className={styles.section}>
          {/* <h3>Personal Information</h3> */}

          <label className={styles.label}>
            First Name <span className={styles.required}>*</span>
          </label>
          <input
            type="text"
            name="first_name"
            value={formData.first_name}
            onChange={handleChange}
            required
            className={styles.input}
          />
          <label className={styles.label}>
            Last Name <span className={styles.required}>*</span>
          </label>
          <input
            type="text"
            name="last_name"
            value={formData.last_name}
            onChange={handleChange}
            required
            className={styles.input}
          />

          <label className={styles.label}>
            Designation <span className={styles.required}>*</span>
          </label>
          <input
            type="text"
            name="designation"
            value={formData.designation}
            onChange={handleChange}
            required
            className={styles.input}
          />

          <label className={styles.label}>
            Organization Name <span className={styles.required}>*</span>
          </label>
          <input
            type="text"
            name="organization_name"
            value={formData.organization_name}
            onChange={handleChange}
            required
            className={styles.input}
          />

          <label className={styles.label}>
            Mobile Number <span className={styles.required}>*</span>
          </label>
          <input
            type="tel"
            name="personal_Number"
            value={formData.personal_Number}
            onChange={handleChange}
            pattern="\d{10}"
            title="Please enter a 10-digit mobile number"
            required
            className={styles.input}
          />

          <label className={styles.label}>
            Email Address <span className={styles.required}>*</span>
          </label>
          <input
            type="email"
            name="personal_email"
            value={formData.personal_email}
            onChange={handleChange}
            required
            className={styles.input}
          />
        </div>

        <button type="submit" className={styles.submitBtn}>
          {loading ? "Submitting..." : "Submit"}
        </button>

        <div className={styles.formGroup}>
          {error && (
            <p className={styles.linkGroup} style={{ color: "red" }}>
              {error}
            </p>
          )}

          {successMessage && (
            <p className={styles.linkGroup} style={{ color: "green" }}>
              {successMessage}
            </p>
          )}
        </div>
      </form>
    </div>
  );
};

export default AdminRegistrationForm;
