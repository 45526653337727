import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  program: {},
  isOpen: false,
  programUpdated: false,
};

const programSlice = createSlice({
  name: "program",
  initialState,
  reducers: {
    updateProgram: (state, action) => {
      state.program = action.payload;
    },
    toggleProgramModal: (state) => {
      state.isOpen = !state.isOpen;
    },
    toggleProgramUpdated: (state) => {
      state.programUpdated = !state.programUpdated;
    },
  },
});

export const { updateProgram, toggleProgramModal, toggleProgramUpdated } =
  programSlice.actions;

export default programSlice.reducer;
