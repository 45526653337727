import Footer from "./components/Footer/Footer";
import Navbar from "./components/Navbar/Navbar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Pages/Pages/Home";
import About from "./Pages/About/About";
import Ourprogram from "./Pages/ourProgram/Ourprogram";
import Contact from "./Pages/Contact/Contact";
import Faq from "./Pages/Faq/Faq";
import ScrollToTop from "./ScrollToTopOnPageChange";
import Register from "./Pages/Register/Register";
import WaitBox from "./components/temp/WaitBox";
import { useState } from "react";
import Style from "./App.module.css";
import RegisterDialogBox from "./components/registration/RegisterDialogBox";
import SchoolRegistrationForm from "./components/registration/school/SchoolRegistrationForm";
import AdminRegistrationForm from "./components/registration/admin/AdminRegistrationForm";
import StudentRegistrationForm from "./components/registration/student/StudentRegistrationForm";
import Modal from "./components/registration/modal/Modal";
import LoginForm from "./components/login/LoginForm";
import LoginModal from "./components/login/LoginModal";
import SchoolPrivate from "./Pages/private/SchoolPrivate";
import StudentPrivate from "./Pages/private/StudentPrivate";
import AdminPrivate from "./Pages/private/AdminPrivate";
import SchoolPage from "./Pages/MainPages/school/SchoolPage";
import AdminPage from "./Pages/MainPages/admin/AdminPage";
import StudentPage from "./Pages/MainPages/student/StudentPage";
import EditRegistration from "./Pages/MainPages/student/subpages/RegisteredPrograms/EditRegistration/EditRegistration";
import { useDispatch, useSelector } from "react-redux";
import { toggleProgramModal } from "./store/user/programSlice";
import ProgramRegistration from "./Pages/MainPages/school/subpages/registerToProgram/register/ProgramRegistration";
import { toggleSchoolProgramModal } from "./store/user/schoolProgramSlice";
import CertificateEditor from "./Pages/MainPages/student/subpages/certificate/editor/CertificateEditor";
import { toggleCertificateModal } from "./store/user/certificateSlice";
import CertificateModal from "./components/registration/modal/certificate/CertificateModal";

function App() {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [isRegisterOpen, setIsRegisterOpen] = useState(false);

  const [showSchoolForm, setShowSchoolForm] = useState(false);
  const [showStudentForm, setShowStudentForm] = useState(false);
  const [showAdminForm, setShowAdminForm] = useState(false);
  const { isOpen: isProgramFormOpen } = useSelector((state) => state.program);
  const { isOpen: isSchoolProgramOpen } = useSelector(
    (state) => state.schoolProgram
  );
  const { isOpen: isCertificateOpen } = useSelector(
    (state) => state.certificate
  );
  const dispatch = useDispatch();

  const handleCloseLoginModal = () => {
    setIsLoginOpen(false);
  };

  const handleOpenSchoolModal = () => {
    setShowSchoolForm(true);
    // setIsLoginOpen(false);
    setIsRegisterOpen(false);
  };

  const handleCloseSchoolModal = () => {
    setShowSchoolForm(false);
  };
  const handleOpenStudentModal = () => {
    setShowStudentForm(true);
    setIsRegisterOpen(false);
  };

  const handleCloseStudentModal = () => {
    setShowStudentForm(false);
  };
  const handleOpenAdminModal = () => {
    setShowAdminForm(true);
    setIsRegisterOpen(false);
  };

  const handleCloseAdminModal = () => {
    setShowAdminForm(false);
  };

  return (
    <>
      <Router>
        <div className={Style.relativeContainer}>
          {isOpen && <WaitBox setIsOpen={setIsOpen} />}
        </div>
        <div className={Style.relativeContainer}>
          {isRegisterOpen && (
            <RegisterDialogBox
              setIsRegisterOpen={setIsRegisterOpen}
              handleOpenSchoolModal={handleOpenSchoolModal}
              handleOpenStudentModal={handleOpenStudentModal}
              handleOpenAdminModal={handleOpenAdminModal}
            />
          )}
        </div>
        <div className={Style.formContainer}>
          <LoginModal show={isLoginOpen} onClose={handleCloseLoginModal}>
            <LoginForm
              handleCloseLoginModal={handleCloseLoginModal}
              setIsRegisterOpen={setIsRegisterOpen}
            />
          </LoginModal>
        </div>
        <div className={Style.formContainer}>
          <Modal show={showSchoolForm} onClose={handleCloseSchoolModal}>
            <SchoolRegistrationForm
              onClose={handleCloseSchoolModal}
              setIsLoginOpen={setIsLoginOpen}
            />
          </Modal>
        </div>
        <div className={Style.formContainer}>
          <Modal show={showStudentForm} onClose={handleCloseStudentModal}>
            <StudentRegistrationForm setIsLoginOpen={setIsLoginOpen} />
          </Modal>
        </div>
        <div className={Style.formContainer}>
          <Modal show={showAdminForm} onClose={handleCloseAdminModal}>
            <AdminRegistrationForm
              onClose={handleCloseAdminModal}
              setIsLoginOpen={setIsLoginOpen}
            />
          </Modal>
        </div>
        <div className={Style.formContainer}>
          <Modal
            show={isProgramFormOpen}
            onClose={() => dispatch(toggleProgramModal())}
          >
            <EditRegistration />
          </Modal>
        </div>
        <div className={Style.formContainer}>
          <Modal
            show={isSchoolProgramOpen}
            onClose={() => dispatch(toggleSchoolProgramModal())}
          >
            <ProgramRegistration />
          </Modal>
        </div>

        <div className={Style.formContainer}>
          <CertificateModal
            show={isCertificateOpen}
            onClose={() => dispatch(toggleCertificateModal())}
          >
            <CertificateEditor />
          </CertificateModal>
        </div>

        <ScrollToTop />
        <Navbar
          setIsOpen={setIsOpen}
          setIsLoginOpen={setIsLoginOpen}
          setIsRegisterOpen={setIsRegisterOpen}
        />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/ourprogram" element={<Ourprogram />} />
          <Route path="/register" element={<Register />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/school-signUp" element={<SchoolRegistrationForm />} />
          <Route path="/student-signUp" element={<StudentRegistrationForm />} />
          <Route path="/admin-signUp" element={<AdminRegistrationForm />} />
          <Route element={<SchoolPrivate />}>
            <Route path="/school" element={<SchoolPage />} />
          </Route>
          <Route element={<StudentPrivate />}>
            <Route path="/student" element={<StudentPage />} />
          </Route>
          <Route element={<AdminPrivate />}>
            <Route path="/admin" element={<AdminPage />} />
          </Route>
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
