import React from "react";
import styles from "./LoginModal.module.css";
import { RxCross2 } from "react-icons/rx";

const LoginModal = ({ show, onClose, children }) => {
  if (!show) {
    return null;
  }

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <button className={styles.closeButton} onClick={onClose}>
          <RxCross2 className={styles.cross} />
        </button>
        {children}
      </div>
    </div>
  );
};

export default LoginModal;
