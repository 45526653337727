import React, { useState } from "react";
import styles from "./SchoolRegistrationForm.module.css";
import axios from "axios";

const SchoolRegistrationForm = ({ onClose, setIsLoginOpen }) => {
  const [formData, setFormData] = useState({
    user_type: "school",
    username: "",
    mobile_no: "",
    email: "",
    password: "",
    confirmPassword: "",
    twoFactorAuth: false,
    school_name: "",
    school_type: "",
    school_board: "",
    add_school: "",
    add_city: "",
    add_dist: "",
    add_state: "",
    add_pin: "",
    school_registration_no: "",
    website: "",
    contact_person: "",
    contact_person_designation: "",
    contact_phone: "",
    contact_email: "",
    terms: false,
    privacy: false,
    referralSource: "",
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const sleep = () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        // alert("Registration successful...");
        setFormData({
          user_type: "school",
          username: "",
          mobile_no: "",
          email: "",
          password: "",
          confirmPassword: "",
          twoFactorAuth: false,
          school_name: "",
          school_type: "",
          school_board: "",
          add_school: "",
          add_city: "",
          add_dist: "",
          add_state: "",
          add_pin: "",
          school_registration_no: "",
          website: "",
          contact_person: "",
          contact_person_designation: "",
          contact_phone: "",
          contact_email: "",
          terms: false,
          privacy: false,
          referralSource: "",
        });
        onClose();
        setIsLoginOpen(true);
      }, 500);
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    setError("");
    setSuccessMessage("");

    try {
      // Make the request to your backend API
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/registerSchool`,
        formData
      ); // Replace with your backend URL

      console.log("Response:", response);

      if (response.status === 201) {
        setSuccessMessage("User and school registered successfully");
        sleep();
      }
    } catch (error) {
      setError(
        error.response ? error.response.data.error : "Registration failed"
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <h2 className={`${styles.title} ${styles.h2}`}>School Sign-Up Form</h2>

      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.section}>
          <label className={styles.label}>
            Username <span className={styles.required}>*</span>
          </label>
          <input
            type="text"
            name="username"
            value={formData.username}
            onChange={handleChange}
            required
            className={styles.input}
          />

          <label className={styles.label}>
            School Contact Number <span className={styles.required}>*</span>
          </label>
          <input
            type="tel"
            name="mobile_no"
            value={formData.mobile_no}
            onChange={handleChange}
            required
            className={styles.input}
          />

          <label className={styles.label}>
            School Email Address <span className={styles.required}>*</span>
          </label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            className={styles.input}
          />

          <label className={styles.label}>
            Password <span className={styles.required}>*</span>
          </label>
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
            className={styles.input}
          />

          <label className={styles.label}>
            Confirm Password <span className={styles.required}>*</span>
          </label>
          <input
            type="password"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
            required
            className={styles.input}
          />
          <div className={styles.checkboxContainer}>
            <label className={styles.label}>
              Two-Factor Authentication{" "}
              <span className={styles.required}>*</span>
            </label>
            <input
              className={styles.input}
              type="checkbox"
              name="twoFactorAuth"
              onChange={() =>
                setFormData({
                  ...formData,
                  twoFactorAuth: !formData.twoFactorAuth,
                })
              }
            />
          </div>
        </div>

        <div className={styles.section}>
          <label className={styles.label}>
            School Name <span className={styles.required}>*</span>
          </label>
          <input
            className={styles.input}
            type="text"
            name="school_name"
            value={formData.school_name}
            onChange={handleChange}
            required
          />
          <label className={styles.label}>
            School Board <span className={styles.required}>*</span>
          </label>
          <input
            className={styles.input}
            type="text"
            name="school_board"
            value={formData.school_board}
            onChange={handleChange}
            required
          />

          <label className={styles.label}>
            School Type <span className={styles.required}>*</span>
          </label>
          <select
            name="school_type"
            value={formData.school_type}
            onChange={handleChange}
            required
            className={styles.select}
          >
            <option value="">Select</option>
            <option value="Primary">Primary</option>
            <option value="Secondary">Secondary</option>
            <option value="Higher Secondary">Higher Secondary</option>
          </select>

          <label className={styles.label}>
            School Address <span className={styles.required}>*</span>
          </label>
          <input
            type="text"
            name="add_school"
            value={formData.add_school}
            onChange={handleChange}
            required
            className={styles.input}
          />

          <div className={styles.grid}>
            <div>
              <label className={styles.label}>
                City <span className={styles.required}>*</span>
              </label>
              <input
                type="text"
                name="add_city"
                value={formData.add_city}
                onChange={handleChange}
                required
                className={styles.input}
              />
            </div>
            <div>
              <label className={styles.label}>
                District <span className={styles.required}>*</span>
              </label>
              <input
                className={styles.input}
                type="text"
                name="add_dist"
                value={formData.add_dist}
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <label className={styles.label}>
                State <span className={styles.required}>*</span>
              </label>
              <input
                className={styles.input}
                type="text"
                name="add_state"
                value={formData.add_state}
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <label className={styles.label}>
                Pin Code <span className={styles.required}>*</span>
              </label>
              <input
                className={styles.input}
                type="text"
                name="add_pin"
                value={formData.add_pin}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <label className={styles.label}>
            School Registration Number{" "}
            <span className={styles.required}>*</span>
          </label>
          <input
            className={styles.input}
            type="text"
            name="school_registration_no"
            value={formData.school_registration_no}
            onChange={handleChange}
            required
          />

          <label className={styles.label}>School Website</label>
          <input
            type="url"
            name="website"
            value={formData.website}
            onChange={handleChange}
            className={styles.input}
          />

          <label className={styles.label}>
            Principal/Administrator Name{" "}
            <span className={styles.required}>*</span>
          </label>
          <input
            type="text"
            name="contact_person"
            value={formData.contact_person}
            onChange={handleChange}
            required
            className={styles.input}
          />

          <label className={styles.label}>
            Designation <span className={styles.required}>*</span>
          </label>
          <select
            name="contact_person_designation"
            value={formData.contact_person_designation}
            onChange={handleChange}
            required
            className={styles.select}
          >
            <option value="">Select</option>
            <option value="Principal">Principal</option>
            <option value="Director">Director</option>
            <option value="Coordinator">Coordinator</option>
          </select>

          <label className={styles.label}>
            Contact Number <span className={styles.required}>*</span>
          </label>
          <input
            type="tel"
            name="contact_phone"
            value={formData.contact_phone}
            onChange={handleChange}
            required
            className={styles.input}
          />

          <label className={styles.label}>
            Email Address <span className={styles.required}>*</span>
          </label>
          <input
            type="email"
            name="contact_email"
            value={formData.contact_email}
            onChange={handleChange}
            required
            className={styles.input}
          />

          <label className={styles.label}>
            Upload School Registration Certificate (Optional)
          </label>
          <input
            className={styles.input}
            type="file"
            name="schoolCertificate"
            accept=".pdf,.jpg,.png"
          />
        </div>

        <div className={styles.section}>
          <div className={styles.checkboxContainer}>
            <label className={styles.label}>
              I agree to the Terms and Conditions{" "}
              <span className={styles.required}>*</span>
            </label>
            <input
              type="checkbox"
              name="terms"
              onChange={() =>
                setFormData({ ...formData, terms: !formData.terms })
              }
              required
              className={styles.input}
            />
          </div>
          <div className={styles.checkboxContainer}>
            <label className={styles.label}>
              I acknowledge the Privacy Policy{" "}
              <span className={styles.required}>*</span>
            </label>
            <input
              type="checkbox"
              name="privacy"
              onChange={() =>
                setFormData({ ...formData, privacy: !formData.privacy })
              }
              required
              className={styles.input}
            />
          </div>
        </div>

        <div className={styles.section}>
          <label className={styles.label}>
            How Did You Hear About Us?(Optional)
          </label>
          <select
            name="referralSource"
            value={formData.referralSource}
            onChange={handleChange}
            className={styles.select}
          >
            <option value="">Select</option>
            <option value="Advertisement">Advertisement</option>
            <option value="Referral">Referral</option>
            <option value="Online Search">Online Search</option>
            <option value="Social Media">Social Media</option>
          </select>
        </div>

        <div className={styles.captcha}>
          <label className={styles.label}>
            Captcha Verification <span className={styles.required}>*</span>
          </label>
        </div>

        <button type="submit" className={styles.submitBtn}>
          {loading ? "Submitting..." : "Submit"}
        </button>

        <div className={styles.formGroup}>
          {error && (
            <p className={styles.linkGroup} style={{ color: "red" }}>
              {error}
            </p>
          )}

          {successMessage && (
            <p className={styles.linkGroup} style={{ color: "green" }}>
              {successMessage}
            </p>
          )}
        </div>
      </form>
    </>
  );
};

export default SchoolRegistrationForm;
